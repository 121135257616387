import React, { FC } from 'react';
import cx from 'clsx';

import { useStyles } from './styles';

interface Props {
  sm?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonRound: FC<Props> = ({
  sm,
  onClick,
  children,
  disabled,
  ...rest
}) => {
  const css = useStyles();

  return (
    <button
      className={cx(css.root, { sm, disabled })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
