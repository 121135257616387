import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';

import { AugmentedDialog, Button, Select } from 'components/ui';
import { IconCheck } from 'components/ui/icons/IconCheck';

import { editUser } from 'utils/api';
import { Prefix, prefixes } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

import { useStyles } from './styles';

interface Props {
  user: User;
}

export const EditAccountProfile: FC<Props> = ({ user: initialUser }) => {
  const css = useStyles();

  const [user, setUser] = useState(initialUser);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showChangeDialog, setShowChangeDialog] = useState(false);

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const [prefix, setPrefix] = useState<Prefix>(user?.profile.prefix as Prefix);
  const [firstName, setFirstName] = useState<string>(user?.first_name);
  const [lastName, setLastName] = useState<string>(user?.last_name);

  const onChangePrefix = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'Prefix') {
      setPrefix(undefined);
    } else {
      setPrefix(e.target.value as Prefix);
    }
  };

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);

    setErrors({
      ...errors,
      firstName: !!!firstName?.length,
      lastName: !!!lastName?.length,
    });

    if (firstName?.length && lastName?.length) {
      const newUser = await editUser({
        first_name: firstName,
        last_name: lastName,
        prefix: prefix || '',
      });

      if (newUser) {
        setUser(newUser);
      }
      setLoading(false);
      setShowChangeDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setFirstName(initialUser.first_name);
    setLastName(initialUser.last_name);
    setPrefix(initialUser.profile.prefix as Prefix);
    setShowChangeDialog(false);
  };

  useEffect(() => {
    setErrors({
      ...errors,
      firstName: firstName !== undefined && !!!firstName.length,
      lastName: lastName !== undefined && !!!lastName.length,
    });

    setDisabled(
      firstName === user.first_name &&
        lastName === user.last_name &&
        prefix === user.profile.prefix
    );
  }, [firstName, lastName, prefix, user]);

  return (
    <div className={css.root}>
      <>
        <Select
          value={prefix || 'Prefix'}
          onChange={onChangePrefix}
          propName="prefix"
          options={prefixes}
        />

        <div className={css.formControl}>
          <TextField
            id="first-name"
            label="First Name"
            fullWidth
            onChange={onChangeFirstName}
            value={firstName || ''}
            className={css.withLabel}
            error={errors.firstName}
            InputProps={{
              endAdornment: firstName !== undefined && !!firstName.length && (
                <InputAdornment position="end" style={{ alignSelf: 'center' }}>
                  <IconCheck />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={css.formControl}>
          <TextField
            id="last-name"
            label="Last Name"
            fullWidth
            onChange={onChangeLastName}
            value={lastName || ''}
            className={css.withLabel}
            error={errors.lastName}
            InputProps={{
              endAdornment: lastName !== undefined && !!lastName.length && (
                <InputAdornment position="end" style={{ alignSelf: 'center' }}>
                  <IconCheck />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={{ paddingBottom: 8 }}>
          <Typography variant="caption">
            We need your name to communicate with you. Also your observables
            will see your name on the test page.
          </Typography>
        </div>

        <TextField
          autoFocus
          id="email"
          label="Email"
          type="email"
          fullWidth
          value={user.email}
          className={cx(css.formControl, css.withLabel)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ alignSelf: 'center' }}>
                <IconCheck />
              </InputAdornment>
            ),
          }}
          disabled
        />

        <div style={{ paddingBottom: 8 }}>
          <Typography variant="caption">
            This e-mail address is{' '}
            {user.profile.email_confirmed ? 'verified' : 'not verified'}.
          </Typography>
        </div>

        <div className={css.formControl}>
          <Button
            type="submit"
            className={css.button}
            onClick={() => setShowChangeDialog(true)}
            disabled={disabled}
          >
            Apply
          </Button>
        </div>
      </>

      <AugmentedDialog open={showChangeDialog} onClose={handleCloseDialog}>
        <div className={css.dialogBlock}>
          <Typography>You made some changes.</Typography>
        </div>

        <div className={css.dialogBlock}>
          <Button className={css.button} onClick={handleCloseDialog}>
            Discard
          </Button>
        </div>

        <div className={css.dialogBlock}>
          <Button variant="default" className={css.button} onClick={onSubmit}>
            {loading ? (
              <CircularProgress
                size={24}
                style={{ color: MAIN_COLORS.UI_LIGHT }}
              />
            ) : (
              'Apply'
            )}
          </Button>
        </div>
      </AugmentedDialog>
    </div>
  );
};
