import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { BORDER, BORDER_BOX_SHADOW } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    position: 'relative',
    background: 'none',
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingRight: spacing(2),
    borderBottom: BORDER,
    boxShadow: BORDER_BOX_SHADOW,
  },
  title: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    '&.rootStack': {
      position: 'relative',
      left: 0,
      transform: 'translateX(0)',
      paddingLeft: spacing(2),
    },
  },
  actionIcon: {
    paddingRight: spacing(2),
    '&:hover:not(.isActionIconActive)': {
      '& path': {
        fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: 'initial',
      },
    },
    '&.isActionIconActive': {
      '& button': {
        backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
      },
      '& path': {
        fill: MAIN_COLORS.UI_LIGHT,
        stroke: 'initial',
      },
    },
  },
}));
