import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiTab: {
    // root: {
    //   textTransform: 'initial',
    // },
  },
};

export const { MuiTab } = overrides;
