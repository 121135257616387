import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: MAIN_COLORS.UI_DARK,
    paddingBottom: 4,
    '&.light': {
      backgroundColor: MAIN_COLORS.BACKGROUND_PERSISTENT_NOTIFICATION,
    },
  },
  text: {
    paddingTop: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    color: MAIN_COLORS.UI_LIGHT,
  },
}));
