import { makeStyles } from '@material-ui/styles';

import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(() => ({
  root: {
    outline: 'none',
    width: 40,
    height: 40,
    '&.sm': {
      width: 32,
      height: 32,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      '& path, & rect': {
        stroke: MAIN_COLORS.CONTROLS_DISABLED,
      },
    },
    '&:hover': {
      '& path, & rect': {
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    backgroundColor: MAIN_COLORS.CONTROLS_SHAPE,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
