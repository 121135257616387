import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { TextField, Slide, InputAdornment } from '@material-ui/core';
import * as Sentry from '@sentry/node';

import { Button, Select } from 'components/ui';
import { IconCheck } from 'components/ui/icons/IconCheck';

import { validateEmail, validatePassword } from 'utils';
import { signup } from 'utils/api';
import { Prefix, prefixes } from 'utils/constants';

import { useStyles } from './styles';

interface Props {
  onRegister: () => void;
}

export const SignUpForm: FC<Props> = ({ onRegister }) => {
  const css = useStyles();

  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState<boolean>();

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const [prefix, setPrefix] = useState<Prefix>('Prefix');
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onChangePrefix = (e: ChangeEvent<HTMLSelectElement>) => {
    setPrefix(e.target.value as Prefix);
  };

  const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const formClear = () => {
    setPrefix('Prefix');
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setPassword(undefined);
  };

  const onSubmit = async () => {
    const emailError = !!!email?.length || !validateEmail(email);
    const passwordError = !!!password?.length || !validatePassword(password);

    setErrors({
      ...errors,
      email: emailError,
      password: passwordError,
    });

    if (!emailError && !passwordError) {
      setLoading(true);

      const user = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        prefix: prefix === 'Prefix' ? undefined : prefix,
      };

      const newUser = await signup(user);
      if (newUser) {
        onRegister();
      } else {
        // TODO
        // fail - setError (snack?)?
      }
      setLoading(false);
      setNext(false);
      formClear();
    }
  };

  const step1 = () => {
    setNext(false);
  };

  const step2 = () => {
    Sentry.captureMessage('SignUp step2');
    setErrors({
      ...errors,
      firstName: !!!firstName?.length,
      lastName: !!!lastName?.length,
    });
    if (firstName?.length && lastName?.length) {
      setNext(true);
    }
  };

  const handleKeyDownStep1 = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      step2();
    }
  };

  const handleKeyDownStep2 = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    setErrors({
      ...errors,
      firstName: firstName !== undefined && !!!firstName.length,
      lastName: lastName !== undefined && !!!lastName.length,
      email: email !== undefined && !!!email.length,
      password: password !== undefined && !!!password.length,
    });
  }, [firstName, lastName, email, password]);

  const prefixString = prefix === 'Prefix' ? '' : prefix;
  const nameString = `Hello ${prefixString} ${firstName} ${lastName}`;

  return (
    <div className={css.root}>
      <Slide
        direction="right"
        in={!next}
        mountOnEnter
        unmountOnExit
        timeout={{ exit: 0, enter: next === undefined ? 0 : 300 }}
      >
        <div>
          <Select
            value={prefix || 'Prefix'}
            onChange={onChangePrefix}
            propName="prefix"
            options={prefixes}
          />

          <div className={css.formControl}>
            <TextField
              id="first-name"
              label="First Name"
              fullWidth
              onChange={onChangeFirstName}
              value={firstName || ''}
              className={css.withLabel}
              error={errors.firstName}
              InputProps={{
                endAdornment: firstName !== undefined && !!firstName.length && (
                  <InputAdornment
                    position="end"
                    style={{ alignSelf: 'center' }}
                  >
                    <IconCheck />
                  </InputAdornment>
                ),
              }}
              // @ts-expect-error wtf material typings issue
              onKeyDown={handleKeyDownStep1}
            />
          </div>

          <div className={css.formControl}>
            <TextField
              id="last-name"
              label="Last Name"
              fullWidth
              onChange={onChangeLastName}
              value={lastName || ''}
              className={css.withLabel}
              error={errors.lastName}
              InputProps={{
                endAdornment: lastName !== undefined && !!lastName.length && (
                  <InputAdornment
                    position="end"
                    style={{ alignSelf: 'center' }}
                  >
                    <IconCheck />
                  </InputAdornment>
                ),
              }}
              // @ts-expect-error wtf material typings issue
              onKeyDown={handleKeyDownStep1}
            />
          </div>

          <div className={css.formControl}>
            <Button type="submit" className={css.formButton} onClick={step2}>
              Continue
            </Button>
          </div>

          <div className={css.formControl}>
            <Typography className={css.text}>
              We need your name to communicate with you. Your observables will
              see your name on the test page. You can change it later.
            </Typography>
          </div>
        </div>
      </Slide>

      <Slide direction="left" in={next} timeout={{ exit: 0, enter: 300 }}>
        <div>
          <div className={css.formControl}>
            <Typography className={css.text}>{nameString}</Typography>
          </div>
          <div className={css.formControl}>
            <TextField
              autoFocus
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              onChange={onChangeEmail}
              value={email || ''}
              className={css.withLabel}
              error={errors.email}
              helperText={
                errors.email ? 'This email address doesn’t look good.' : ''
              }
              // @ts-expect-error wtf material typings issue
              onKeyDown={handleKeyDownStep2}
            />
          </div>
          <div className={css.formControl}>
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              onChange={onChangePassword}
              value={password || ''}
              className={css.withLabel}
              error={errors.password}
              helperText={
                errors.password
                  ? 'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                  : ''
              }
              // @ts-expect-error wtf material typings issue
              onKeyDown={handleKeyDownStep2}
            />
          </div>

          <div className={css.formControl}>
            <Button
              type="submit"
              className={css.formButton}
              onClick={onSubmit}
              disabled={loading}
            >
              Sign Up
            </Button>
          </div>

          <div className={css.formControl}>
            <Button
              variant="secondary"
              className={css.formButton}
              onClick={step1}
              disabled={loading}
            >
              Back
            </Button>
          </div>

          <div className={css.formControl}>
            <Typography className={css.text}>
              We need your email and password to identify you next time you will
              log in to Motoom.
            </Typography>
          </div>
        </div>
      </Slide>
    </div>
  );
};
