import React, { FC } from 'react';
import { Collapse, Typography } from '@material-ui/core';
import cx from 'clsx';

import { Button } from 'components/ui';

import { useStyles } from './styles';

interface Props {
  show: boolean;
  text: string;
  light?: boolean;
  actionPrimary?: () => void;
  actionPrimaryText?: string;
  actionSecondary?: () => void;
  actionSecondaryText?: string;
  disabledAction?: boolean;
}

export const Notification: FC<Props> = ({
  show,
  text,
  light,
  actionPrimary,
  actionPrimaryText,
  actionSecondary,
  actionSecondaryText,
  disabledAction,
}) => {
  const css = useStyles();

  return (
    <Collapse in={show}>
      <div className={cx(css.root, { light })}>
        <div className={css.text}>
          <Typography>{text}</Typography>
        </div>
        {actionPrimary && (
          <Button
            variant="secondary"
            onClick={actionPrimary}
            disabled={disabledAction}
          >
            {actionPrimaryText}
          </Button>
        )}
        {actionSecondary && (
          <Button
            variant="secondary"
            onClick={actionSecondary}
            disabled={disabledAction}
          >
            {actionSecondaryText}
          </Button>
        )}
      </div>
    </Collapse>
  );
};
