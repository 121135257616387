import { Overrides } from '@material-ui/core/styles/overrides';
import { Color } from '../constants';

const overrides: Overrides = {
  MuiSnackbarContent: {
    // root: {
    //   '&.default': {},
    //   '&.success': {
    //     backgroundColor: Color.secondaryLight,
    //   },
    //   '&.error': {
    //     backgroundColor: Color.errorMain,
    //   },
    //   '&.warning': {
    //     backgroundColor: Color.errorLight,
    //   },
    // },
  },
};

export const { MuiSnackbarContent } = overrides;
