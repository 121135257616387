import React, { FC, useState, useRef, useEffect } from 'react';
import useSWR from 'swr';
import cx from 'clsx';
import { getTests } from 'utils/api';
import { useStyles } from './styles';
import { Label } from 'components/ui';

import {
  getDates,
  getCurrentTestId,
  getParams,
  getSorted,
  Data,
  getData,
  isPathMuted,
  isDotVisible,
  isLabelVisible,
  getTestCSSLeft,
  getLabelCSSLeft,
  getLabelCSSRight,
  getTestMarkPath,
  getLabelCSSTop,
} from './utils';
import { getTestDate, testsRevSortFn } from 'utils';

interface Props {
  patientId: number;
}

export const DynamicsGraph: FC<Props> = ({ patientId }) => {
  const css = useStyles();
  // const [testData, setTestData] = useState<string>('┗┃・ ■ ・┃┛');
  const [isHovering, setHovering] = useState<boolean>(false);
  const [currentTestId, setCurrentTestId] = useState<number>(-1);
  const [currentParamId, setCurrentParamId] = useState<number>(-1);
  const [graphData, setGraphData] = useState<Data>(getData());

  const graphBodyRef = useRef<HTMLDivElement>(null);
  const graphWrapperRef = useRef<HTMLDivElement>(null);

  const { data = [] } = useSWR(['/api/tests/', patientId], (url, patientId) =>
    getTests(patientId)
  );

  const onMouseEnter = () => {
    setHovering(true);
  };

  const onMouseLeave = () => {
    setHovering(false);
    setCurrentTestId(tests.length - 1);
  };

  const onSelectorClick = (id: number) => {
    setCurrentParamId(id === currentParamId ? -1 : id);
  };

  const onMouseMove = (event) => {
    if (isHovering) {
      if (event.currentTarget !== graphWrapperRef.current) {
        return;
      }
      const testId = getCurrentTestId(event, graphBodyRef.current, graphData);
      if (currentTestId === testId) {
        return;
      }
      setCurrentTestId(testId);
    }
  };

  const tests = data.sort(testsRevSortFn);

  useEffect(() => {
    const sorted = getSorted(tests);
    const dates = getDates(tests);
    const params = getParams(sorted, dates);
    setCurrentTestId(tests.length - 1);
    setGraphData({
      dates,
      params,
    });
  }, [tests.length]);

  return (
    <>
      {tests.length > 1 ? (
        <div className={css.rootGraphContainer}>
          <div
            className={css.graphWrapper}
            ref={graphWrapperRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
          >
            <div ref={graphBodyRef} className={css.graphContainer}>
              <svg
                className={css.graphSVG}
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {graphData.dates.map((v, i) => (
                  <path
                    key={'test-mark' + i}
                    className={cx(css.testMark, {
                      current: currentTestId == i,
                    })}
                    d={getTestMarkPath(v)}
                  />
                ))}
                <path
                  className={cx(css.testMark, { current: true })}
                  d="M-20 100H120"
                />
              </svg>
              {graphData.params.map((v, i) => (
                <div
                  key={'param-container' + i}
                  className={cx(css.layer, {
                    above: currentParamId === i,
                    below: currentParamId !== i && currentParamId !== -1,
                  })}
                >
                  <svg
                    className={css.graphSVG}
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d={v.path}
                      stroke={v.color}
                      className={cx(css.graphPath, {
                        muted: isPathMuted(i, currentParamId),
                      })}
                    />
                  </svg>
                  <div
                    className={cx(css.graphPoint, {
                      visible: isDotVisible(currentTestId, i, currentParamId),
                    })}
                    style={{
                      backgroundColor: v.color,
                      top: (1 - v.values[currentTestId]) * 100 + '%',
                      left: getTestCSSLeft(graphData, currentTestId),
                    }}
                  ></div>
                </div>
              ))}
              <div className={cx(css.layer, css.legend)}>
                {graphData.params.map((v, i) => (
                  <Label
                    key={'legend-label' + i}
                    className={cx(css.legendLabel, {
                      visible: isLabelVisible(i, currentParamId, isHovering),
                    })}
                    variant="small"
                    style={{
                      top: getLabelCSSTop(
                        graphData.params,
                        i,
                        currentTestId,
                        120,
                        20,
                        currentParamId
                      ),
                      left: getLabelCSSLeft(graphData, currentTestId),
                      right: getLabelCSSRight(graphData, currentTestId),
                    }}
                  >
                    {currentTestId !== -1 ? v.readable[currentTestId] : '0'}
                  </Label>
                ))}
              </div>
            </div>
            <div className={css.dateLabelsContainer}>
              {tests.map((test, i) => (
                <div
                  key={'data-label' + i}
                  className={cx(css.dateLabel, {
                    visible: !isHovering && (i == 0 || i == tests.length - 1),
                  })}
                  style={{
                    left: getLabelCSSLeft(graphData, i),
                    right: getLabelCSSRight(graphData, i),
                  }}
                >
                  <Label variant="small">{getTestDate(test.created_at)}</Label>
                </div>
              ))}
              <div
                className={cx(css.dateLabel, css.currentDateLabel, {
                  visible: isHovering,
                })}
                style={{
                  left: getLabelCSSLeft(graphData, currentTestId),
                  right: getLabelCSSRight(graphData, currentTestId),
                }}
              >
                <Label variant="small">
                  {tests[currentTestId]
                    ? getTestDate(tests[currentTestId].created_at)
                    : null}
                </Label>
              </div>
            </div>
          </div>
          <div className={css.selectorContainer}>
            {graphData.params.map((v, i) => (
              <div
                className={cx(css.selector, {
                  selected: currentParamId == i,
                })}
                onClick={() => onSelectorClick(i)}
                key={'param-selector' + i}
              >
                <div
                  className={cx(css.selectorDash, {
                    selected: currentParamId == i,
                  })}
                  style={{ backgroundColor: v.color }}
                ></div>
                <Label variant="small">{v.name}</Label>
              </div>
            ))}
            <div
              className={cx(css.selector, {
                invisible: currentParamId == -1,
              })}
              onClick={() => onSelectorClick(-1)}
            >
              <Label variant="small">×</Label>
            </div>
            <div className={css.floatKiller}></div>
          </div>
          {/* {testData} */}
        </div>
      ) : null}
    </>
  );
};
