import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
    width: 72,
    '&.selected': {
      backgroundColor: MAIN_COLORS.BACKGROUND_TIMER_NOTIFICATION,
    },
    [breakpoints.only('xs')]: {
      height: 60,
      marginLeft: 16,
      marginRight: 16,
    },
  },
  label: {
    color: MAIN_COLORS.UI_LIGHT,
    paddingTop: 4,
    '&.selected': {
      color: MAIN_COLORS.CONTROLS_ACTIVE,
    },
  },
}));
