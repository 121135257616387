import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { Button, ButtonRound, ListItemBasic } from 'components/ui';
import { IconCopy } from 'components/ui/icons/IconCopy';

import { copy, patientUrl } from 'utils';

import { useStyles } from './styles';

interface Props {
  patient?: Patient;
  onChange: () => void;
}

export const ChangePatientLink: FC<Props> = ({ patient, onChange }) => {
  const css = useStyles();

  return (
    <>
      <div>
        <div className={css.link}>
          <ListItemBasic
            title={patient ? patientUrl(patient.hash_url) : ''}
            value={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonRound
                  sm
                  onClick={() => copy(patientUrl(patient.hash_url))}
                >
                  <IconCopy />
                </ButtonRound>
              </div>
            }
            last
          />
        </div>

        <Typography className={css.text}>
          It’s a link to online test for your observable ({patient?.name}). Send
          it privately, so only {patient?.name} has access to it. Change the
          link if it’s exposed.
        </Typography>
        <Button variant="default" className={css.button} onClick={onChange}>
          Change...
        </Button>
      </div>
    </>
  );
};
