import { makeStyles } from '@material-ui/styles';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 440,
    paddingTop: 60,
  },
  formControl: {
    padding: '8px 0',
  },
  formButton: {
    width: '100%',
  },
  withLabel: {
    '& > .MuiInputBase-root': {
      alignItems: 'flex-end',
      padding: '8px 16px',
      '& > .MuiInputBase-input': {
        padding: 0,
      },
    },
  },
  text: {
    color: MAIN_COLORS.UI_READABLE_TEXT,
  },
}));
