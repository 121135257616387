import { Overrides } from '@material-ui/core/styles/overrides';
import { Color } from '../constants';

const overrides: Overrides = {
  MuiMenuItem: {
    // root: {
    //   color: Color.textSecondary,
    //   '&$selected': {
    //     color: Color.primaryContrastText,
    //     backgroundColor: Color.primaryMain,
    //   },
    // },
  },
};

export const { MuiMenuItem } = overrides;
