import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Head from 'next/head';
import * as Sentry from '@sentry/node';

import { Layout } from 'components/Layout';
import { theme } from 'theme/index';
import 'assets/styles.css';
import { WindowWidthProvider } from 'utils/WindowWidthProvider';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export default function MyApp(props) {
  const { Component, pageProps, err } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>Motoom</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <WindowWidthProvider>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Layout {...pageProps}>
            <Component {...pageProps} err={err} />
          </Layout>
        </ThemeProvider>
      </WindowWidthProvider>
    </React.Fragment>
  );
}
