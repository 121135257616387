import React, { FC, useState } from 'react';
import cx from 'clsx';

import { Label } from 'components/ui';
import { IconDisclosure } from 'components/ui/icons/IconDisclosure';

import { useStyles } from './styles';

interface Props {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  selected?: boolean;
  last?: boolean;
  onClick?: () => void;
  action?: string | JSX.Element;
  disclosure?: boolean;
  disclosureValue?: string | JSX.Element;
  counter?: number;
  slim?: boolean;
  dataTest?: string;
}

export const ListItemThick: FC<Props> = ({
  title,
  subtitle,
  last,
  selected,
  onClick,
  action,
  disclosure,
  disclosureValue,
  counter,
  slim,
  dataTest,
}) => {
  const css = useStyles();

  const [active, setActive] = useState(false);

  return (
    <div
      className={cx(css.root, { selected, onClick })}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={onClick}
      data-test={dataTest}
    >
      <div className={cx(css.container, { last, slim })}>
        <div className={css.titleContainer}>
          <div className={css.title}>
            <Label>{title}</Label>
          </div>
          <div className={cx(css.subtitle, { active, selected })}>
            <Label variant="small">{subtitle}</Label>
          </div>
        </div>

        <div className={css.disclosure}>
          {action && (
            <div className={cx(css.action, { active, selected })}>
              <Label bold>{action}</Label>
            </div>
          )}
          {counter > 0 ? (
            <div
              className={cx(css.counter, { active, selected })}
              data-test="counter"
            >
              <Label variant="small" bold>
                {counter}
              </Label>
            </div>
          ) : null}
          {disclosureValue && <Label>{disclosureValue}</Label>}
          {disclosure && <IconDisclosure />}
        </div>
      </div>
    </div>
  );
};
