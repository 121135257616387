import { NextPageContext } from 'next';
import { parseCookies, destroyCookie, setCookie } from 'nookies';
import Axios from 'axios';
import { mutate } from 'swr';

const getBaseUrl = () => {
  const isServer = typeof window === 'undefined';
  const base = isServer ? 'http://nginx:80' : '';
  return base;
};

const tokenHeader = (cookies: { [key: string]: string }) => ({
  Authorization: `Token ${cookies['motoom-token']}`,
});

export const getActivation = async (ctx: NextPageContext) => {
  const base = getBaseUrl();

  try {
    const res = await Axios.get(
      `${base}/api/activate/${ctx.query.a}/${ctx.query.b}`
    );

    return res.status;
  } catch (error) {
    // console.error(error);
    return error.response.status;
  }
};

export const getInitialUser = async (ctx?: NextPageContext) => {
  const cookies = parseCookies(ctx);
  const base = getBaseUrl();

  if (cookies['motoom-token']) {
    try {
      const res = await Axios.get<User>(`${base}/api/auth/user/`, {
        headers: { ...tokenHeader(cookies) },
      });

      return res.data;
    } catch (error) {
      // console.error(error);
      return null;
    }
  }
};

export const getPlans = async (ctx?: NextPageContext) => {
  const cookies = parseCookies(ctx);
  const base = getBaseUrl();

  try {
    const res = await Axios.get<Plan[]>(`${base}/api/plans/`, {
      headers: { ...tokenHeader(cookies) },
    });

    return res.data;
  } catch (error) {
    // console.error(error);
    return [];
  }
};

export const getPatients = async (ctx?: NextPageContext) => {
  const cookies = parseCookies(ctx);
  const base = getBaseUrl();

  try {
    const res = await Axios.get<Patient[]>(`${base}/api/patients/`, {
      headers: { ...tokenHeader(cookies) },
    });

    return res.data;
  } catch (error) {
    // console.error(error);
    return [];
  }
};

export const getTests = async (patientId: number) => {
  const cookies = parseCookies();
  const base = getBaseUrl();

  try {
    const res = await Axios.get<Test[]>(
      `${base}/api/tests/?patient=${patientId}`,
      {
        headers: { ...tokenHeader(cookies) },
      }
    );

    return res.data;
  } catch (error) {
    // console.error(error);
    return [];
  }
};

export const addPatient = async (patient: AddPatientPayload) => {
  const cookies = parseCookies();

  try {
    const res = await Axios.post<Patient>('/api/patients/', patient, {
      headers: { ...tokenHeader(cookies) },
    });

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const editPatient = async (patient: EditPatientPayload) => {
  const cookies = parseCookies();

  try {
    const res = await Axios.put<Patient>(
      `/api/patients/${patient.id}/`,
      patient,
      {
        headers: { ...tokenHeader(cookies) },
      }
    );

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const deletePatient = async (id: number) => {
  const cookies = parseCookies();

  try {
    const res = await Axios.delete<Patient>(`/api/patients/${id}/`, {
      headers: tokenHeader(cookies),
    });
    return res.status;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const editTest = async (test: TestEditPayload) => {
  const cookies = parseCookies();

  try {
    const res = await Axios.put<Test>(`/api/tests/${test.id}/`, test, {
      headers: tokenHeader(cookies),
    });

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const addTest = async (test: TestAddPayload) => {
  try {
    const res = await Axios.post<Test>('/api/tests/', test);

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const getPatientByHash = async (ctx: NextPageContext, hash: string) => {
  const base = getBaseUrl();

  try {
    const res = await Axios.get<TestHash>(`${base}/api/patient-hash/${hash}`);
    return res.data;
  } catch (error) {
    // console.error(error);
    // return {};
  }
};

export const signup = async (payload: SignUpPayload) => {
  try {
    const res = await Axios.post<{
      token: string;
      user: User;
    }>('/api/auth/register/', payload);

    if (res.data.token && res.data.user) {
      setCookie(null, 'motoom-token', res.data.token, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      mutate('/api/auth/user/', { ...res.data.user }, false);
    }

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  try {
    const res = await Axios.post<{
      token: string;
      user: { username: string; id: number };
    }>('/api/auth/login/', {
      username,
      password,
    });

    if (res.data.token && res.data.user) {
      setCookie(null, 'motoom-token', res.data.token, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      mutate('/api/auth/user/', { ...res.data.user }, false);
    }

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const deleteAccount = async () => {
  const cookies = parseCookies();

  try {
    const res = await Axios.get('/api/delete-account/', {
      headers: { ...tokenHeader(cookies) },
    });
    destroyCookie(null, 'motoom-token');
    mutate('/api/auth/user/');
    mutate('/api/patients/');

    return res;
  } catch (error) {
    // console.error(error);
  }
};

export const logout = async () => {
  const cookies = parseCookies();

  try {
    const res = await Axios.post(
      '/api/auth/logout/',
      {},
      {
        headers: { ...tokenHeader(cookies) },
      }
    );
    destroyCookie(null, 'motoom-token');
    mutate('/api/auth/user/');
    mutate('/api/patients/');

    return res;
  } catch (error) {
    // console.error(error);
  }
};

export const editUser = async (user: EditUserPayload) => {
  const cookies = parseCookies();

  try {
    const res = await Axios.put<User>('/api/auth/user/', user, {
      headers: { ...tokenHeader(cookies) },
    });

    if (res.data) {
      mutate('/api/auth/user/', { ...res.data }, false);
    }

    return res.data;
  } catch (error) {
    // console.error(error);
    return null;
  }
};
