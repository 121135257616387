import React, { FC } from 'react';
import cx from 'clsx';

import { Label } from 'components/ui';

import { useStyles } from './styles';

interface Props {
  title: string;
  value?: string | JSX.Element;
  last?: boolean;
  onClick?: () => void;
  area?: boolean;
  dataTest?: string;
  dataTestValue?: string;
}

export const ListItemBasic: FC<Props> = ({
  title,
  value,
  last,
  onClick,
  area,
  dataTest,
  dataTestValue,
}) => {
  const css = useStyles();

  return (
    <div
      className={cx(css.root, { onClick })}
      onClick={onClick}
      data-test={dataTest}
    >
      <div className={cx(css.container, { last, area })}>
        <div className={cx(css.title, { area })}>
          <Label>{title}</Label>
        </div>
        <div className={cx(css.value, { area })}>
          {value && <Label dataTest={dataTestValue}>{value}</Label>}
        </div>
      </div>
    </div>
  );
};
