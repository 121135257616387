export enum PATHS {
  HOME = '/',
  SIGNUP = '/signup',
  ACTIVATE = '/activate',
  TEST = '/[hash]/test',
  HELP = '/help',
  HELP_HOW_TO_START = '/help/how-to-start',
  HELP_TEST_ANALYTICS = '/help/test-analytics',
  OBSERVABLES = '/observables',
  OBSERVABLE = '/observables/[observable]',
  SETTINGS = '/settings',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_PLAN_AND_BILLING = '/settings/plan-and-billing',
}

export enum PATHS_ACTIONS {
  PROFILE = 'profile',
  PROFILE_EDIT = 'profile-edit',
  PROFILE_DELETE = 'profile-delete',
  PROFILE_LINK_EDIT = 'patient-link-edit',
  PROFILE_LINK_EDIT_CONFIRM = 'patient-link-edit-confirm',
}

export enum LAYOUTS {
  HOME = 'home',
  PATIENT_TEST = 'patient-test',
  NOT_FOUND = '404',
}
