import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiContainer: {
    // root: {
    //   paddingLeft: 16,
    //   paddingRight: 16,
    //   '@media (min-width: 415px)': {
    //     paddingLeft: 16,
    //     paddingRight: 16,
    //   },
    // },
  },
};

export const { MuiContainer } = overrides;
