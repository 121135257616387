import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    color: MAIN_COLORS.CONTROLS_ACTIVE,
    '&.onClick': {
      cursor: 'pointer',
    },
    '&:hover:not(.disabled), &.selected:not(.disabled)': {
      color: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      '& path': {
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    '&.stress': {
      color: MAIN_COLORS.CONTROLS_CRITICAL,
      '&:hover, &.selected': {
        color: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
        '& path': {
          stroke: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
        },
      },
    },
    '&.muted': {
      color: MAIN_COLORS.CONTROLS_MUTED,
      '&:hover, &.selected': {
        color: MAIN_COLORS.CONTROLS_MUTED_HOVER,
        '& path': {
          stroke: MAIN_COLORS.CONTROLS_MUTED_HOVER,
        },
      },
    },
    '&.disabled': {
      color: MAIN_COLORS.CONTROLS_DISABLED,
      cursor: 'not-allowed',
      '& path': {
        stroke: MAIN_COLORS.CONTROLS_DISABLED,
        '&:hover, &.selected': {
          stroke: MAIN_COLORS.CONTROLS_DISABLED,
        },
      },
    },
  },
  container: {
    height: 56,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
  text: {
    // paddingLeft: 6,
  },
  icon: {
    paddingRight: 6,
  },
}));
