import dayjs from 'dayjs';

import { PUBLIC_URL } from './constants';

export const validateEmail = (value: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const validatePassword = (value: string) => {
  const re =
    /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9±!@#$%^&*()_+=:"|;'<>?,.~`\-\[\]\{\}\\\/]{8,}$/;
  return re.test(value);
};

export const getTestDate = (date: string) =>
  dayjs(date).format('MMM DD. YYYY HH:mm');

export const getTestDateTime = (date: string) => dayjs(date).valueOf();

export const testsSortFn = (a: Test, b: Test) =>
  dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf();

export const testsRevSortFn = (a: Test, b: Test) =>
  dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf();

export const randomPatientHash = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  return [...Array(5)]
    .map(() => chars[~~(Math.random() * chars.length)])
    .join('');
};

export const patientUrl = (hash: string) => `${PUBLIC_URL}/${hash}/test`;

export const copy = (v: string) => navigator.clipboard.writeText(v);
