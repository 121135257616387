import { ComponentsProps } from '@material-ui/core/styles/props';
import { MuiInput } from './MuiInput';
import { MuiTableCell } from './MuiTableCell';

export const props: ComponentsProps = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiInput,
  MuiTableCell,
};
