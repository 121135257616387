import React, { FC, useState, useEffect, createContext } from 'react';

export const WindowWidthContext = createContext<number>(0);

export const WindowWidthProvider: FC = ({ children }) => {
  const [width, setWidth] = useState(0);

  const onResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <WindowWidthContext.Provider value={width}>
      {children}
    </WindowWidthContext.Provider>
  );
};
