import React, { FC, CSSProperties } from 'react';
import cx from 'clsx';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

import { PUBLIC_URL } from 'utils/constants';
// import { Link as NextLink } from 'src/i18n';
// import { Link as DynamicLink } from 'src/utils/dynamicRoutes';
// import { useTranslation } from 'src/i18n';

import { useStyles } from './styles';

interface BaseProps {
  'aria-label'?: string;
}

interface DynamicLinkProps extends BaseProps {
  // dynamic: true;
  route: string;
  params?: unknown;
  name?: string;
}

interface CommonLinkProps extends BaseProps {
  dynamic?: false;
  href: string;
  className?: string;
  style?: CSSProperties;
  forwardQuery?: boolean;
  target?: string;
  onClick?: () => void;
  shallow?: boolean;
  as?: string;
  name?: string;
}

type Props = DynamicLinkProps | CommonLinkProps;

export const Link: FC<Props> = (props) => {
  // const { dynamic = false, children } = props;
  const { children } = props;
  // const { language } = useTranslation().i18n;
  const css = useStyles();

  // if (dynamic) {
  //   const {
  //     route,
  //     params = {},
  //     'aria-label': ariaLabel,
  //   } = props as DynamicLinkProps;

  //   return (
  //     <DynamicLink route={route} params={{ ...params, lang: language }}>
  //       <a aria-label={ariaLabel} className={css.link}>
  //         {children}
  //       </a>
  //     </DynamicLink>
  //   );
  // }

  const {
    href,
    className,
    // forwardQuery = true,
    target,
    onClick = () => undefined,
    as,
    // name,
    'aria-label': ariaLabel,
    ...rest
  } = props as CommonLinkProps;
  // const { topicId, postId, lng, subpath, ...query } = useRouter().query;

  const url = new URL(href, PUBLIC_URL);

  // if (forwardQuery) {
  //   Object.keys(query).forEach((param) => {
  //     url.searchParams.append(
  //       param,
  //       encodeURIComponent(query[param] as string)
  //     );
  //   });
  // }

  // Link to external resources
  if (url.origin !== PUBLIC_URL) {
    return (
      <a
        href={url.href}
        className={cx(css.link, className)}
        aria-label={ariaLabel}
        {...rest}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={as ? href : { pathname: url.pathname }} {...rest} as={as}>
      <a
        className={cx(css.link, className)}
        aria-label={ariaLabel}
        target={target}
        onClick={onClick}
      >
        {children}
      </a>
    </NextLink>
  );
};
