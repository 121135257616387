import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiAppBar: {
    // root: {
    //   '&.bottom': {
    //     top: 'auto',
    //     bottom: 0,
    //   },
    // },
  },
};

export const { MuiAppBar } = overrides;
