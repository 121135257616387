import React, { FC } from 'react';

export const IconMoreActions: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10C5 11.1046 4.10457 12 3 12C1.89543 12 1 11.1046 1 10C1 8.89543 1.89543 8 3 8C4.10457 8 5 8.89543 5 10Z"
      fill="#00B595"
    />
    <path
      d="M19 10C19 11.1046 18.1046 12 17 12C15.8954 12 15 11.1046 15 10C15 8.89543 15.8954 8 17 8C18.1046 8 19 8.89543 19 10Z"
      fill="#00B595"
    />
    <path
      d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
      fill="#00B595"
    />
  </svg>
);
