import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import cx from 'clsx';
import { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  TextField,
  Snackbar,
  SnackbarContent,
  CircularProgress,
} from '@material-ui/core';

import { Button, ButtonRound } from 'components/ui';
import { IconClose } from 'components/ui/icons/IconClose';

import { validateEmail, validatePassword } from 'utils';
import { TransitionUp } from 'utils/transitions';
import { login } from 'utils/api';

import { useStyles } from './styles';
import { MAIN_COLORS } from 'utils/colors';
import { PATHS } from 'utils/paths';

interface Props {
  open?: boolean;
  setOpen: (show: boolean) => void;
}

export const LoginDialog: FC<Props> = ({ open = false, setOpen }) => {
  const css = useStyles();
  const router = useRouter();
  const { mutate } = useSWRConfig();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false,
  });

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    const emailError = !!!email?.length || !validateEmail(email);
    const passwordError = !!!password?.length || !validatePassword(password);

    setFormErrors({
      ...formErrors,
      email: emailError,
      password: passwordError,
    });

    if (!emailError && !passwordError) {
      setLoading(true);

      const res = await login({ username: email, password });

      if (res?.user) {
        mutate('/api/patients/');
        // formClear();
        router.push(PATHS.OBSERVABLES);
        setOpen(false);
        setLoading(false);
      } else {
        // formClear();
        setError(true);
        setLoading(false);
      }
    }
  };

  // const formClear = () => {
  //   setEmail('');
  //   setPassword('');
  // };

  useEffect(() => {
    setFormErrors({
      ...formErrors,
      email: email !== undefined && !!!email.length,
      password: password !== undefined && !!!password.length,
    });
  }, [email, password]);

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      onClose={onClose}
      classes={{ root: css.root, paper: cx(css.paper) }}
      scroll="paper"
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <SnackbarContent
          className={css.snack}
          message="Please check your credentials"
        />
      </Snackbar>
      <DialogTitle className={css.dialogTitle}>
        <div className={css.info}>
          <div>
            <Typography variant="h3">Log In</Typography>
          </div>
          <ButtonRound onClick={() => setOpen(false)}>
            <IconClose lg />
          </ButtonRound>
        </div>
      </DialogTitle>

      <DialogContent className={css.dialogContent}>
        <TextField
          autoFocus
          id="email"
          placeholder="Email Address"
          type="email"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeEmail}
          value={email || ''}
          className={css.formControl}
          error={formErrors.email}
          helperText={
            formErrors.email ? 'This email address doesn’t look good.' : ''
          }
          // @ts-expect-error wtf material typings issue
          onKeyDown={handleKeyDown}
        />
        <TextField
          id="password"
          placeholder="Password"
          type="password"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangePassword}
          value={password || ''}
          className={cx(css.formControl)}
          error={formErrors.password}
          helperText={
            formErrors.password
              ? 'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
              : ''
          }
          // @ts-expect-error wtf material typings issue
          onKeyDown={handleKeyDown}
        />

        <Button
          type="submit"
          className={css.formControl}
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={24}
              style={{ color: MAIN_COLORS.CONTROLS_ACTIVE }}
            />
          ) : (
            'Log In'
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
