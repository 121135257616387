import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { BORDER_RADIUS } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  rootGraphContainer: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    marginBottom: spacing(4),
  },
  graphWrapper: {
    backgroundColor: MAIN_COLORS.BACKGROUND_LIGHTER_GREY,
    borderRadius: BORDER_RADIUS,
    overflow: 'auto',
  },
  graphContainer: {
    height: 120,
    marginTop: spacing(2),
    marginLeft: spacing(2),
    marginRight: spacing(2),
    position: 'relative',
  },
  legend: {
    zIndex: 4,
  },
  graphSVG: {
    width: '100%',
    height: 120,
    display: 'block',
    overflow: 'visible',
    fill: 'none',
    position: 'absolute',
  },
  testMark: {
    vectorEffect: 'non-scaling-stroke',
    strokeWidth: '1',
    stroke: MAIN_COLORS.GRAPH_TEST_MARK,
    '&.current': {
      stroke: MAIN_COLORS.GRAPH_TEST_MARK_CURRENT,
    },
  },
  graphPath: {
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    vectorEffect: 'non-scaling-stroke',
    strokeWidth: '3',
    '&.muted': {
      stroke: MAIN_COLORS.UI_BORDER,
    },
  },
  graphPoint: {
    backgroundColor: '#000',
    width: 12,
    height: 12,
    borderRadius: 6,
    marginTop: -6,
    marginLeft: -6,
    position: 'absolute',
    visibility: 'hidden',
    '&.visible': {
      visibility: 'visible',
    },
  },
  legendLabel: {
    marginTop: -10,
    marginLeft: 12,
    marginRight: 12,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 3,
    position: 'absolute',
    backgroundColor: MAIN_COLORS.GRAPH_LEGEND_LABEL_BACKGROUND,
    color: MAIN_COLORS.UI_DARK,
    visibility: 'hidden',
    '&.visible': {
      visibility: 'visible',
    },
    '&.small': {
      lineHeight: '20px',
    },
  },
  dateLabelsContainer: {
    position: 'relative',
    height: 18,
    marginLeft: spacing(2),
    marginRight: spacing(2),
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  dateLabel: {
    color: MAIN_COLORS.GRAPH_DATE_LABEL,
    position: 'absolute',
    whiteSpace: 'nowrap',
    visibility: 'hidden',
    '&.visible': {
      visibility: 'visible',
    },
  },
  currentDateLabel: {
    color: MAIN_COLORS.GRAPH_CURRENT_DATE_LABEL,
  },
  layer: {
    position: 'absolute',
    width: '100%',
    height: 120,
    top: 0,
    left: 0,
    '&.above': {
      zIndex: 3,
    },
    '&.below': {
      zIndex: 1,
    },
  },
  selectorContainer: {
    marginTop: spacing(1),
  },
  selector: {
    float: 'left',
    userSelect: 'none',
    padding: '4px 8px',
    borderRadius: BORDER_RADIUS,
    marginRight: spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE_HOVER,
    },
    '&.selected': {
      backgroundColor: MAIN_COLORS.CONTROLS_MUTED,
      color: MAIN_COLORS.UI_LIGHT,
    },
    '&.invisible': {
      visibility: 'hidden',
    },
  },
  selectorDash: {
    width: 16,
    height: 3,
    borderRadius: 4,
    marginRight: 6,
    backgroundColor: MAIN_COLORS.UI_DARK,
    '&.selected': {
      backgroundColor: MAIN_COLORS.UI_LIGHT + '!important',
    },
  },
  floatKiller: {
    clear: 'both',
  },
}));
