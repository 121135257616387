import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import {
  COL2_MENU_WIDTH,
  MENU_WIDTH_SMALL,
  BORDER,
  LEFT_COLUMN_MENU_WIDTH,
  TAB_BAR_HEIGHT_MOBILE,
  BORDER_BOX_SHADOW,
} from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }: Theme) => ({
    logo: {
      position: 'fixed',
      color: MAIN_COLORS.UI_LIGHT,
      top: 12,
      left: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 99,
    },
    notFound: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    layout: {
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: `auto`,
      gridTemplateRows: `auto ${TAB_BAR_HEIGHT_MOBILE}px`,
      gridTemplateAreas: `'content' \n'menu'`,

      [breakpoints.up('sm')]: {
        display: 'grid',
        gridTemplateColumns: `${MENU_WIDTH_SMALL}px auto`,
        gridTemplateRows: `auto`,
        gridTemplateAreas: `'menu content'`,
      },
      [breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: `${MENU_WIDTH_SMALL}px ${COL2_MENU_WIDTH}px auto`,
        gridTemplateRows: `auto`,
        gridTemplateAreas: `'menu col2 content'`,
      },
      [breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: `${MENU_WIDTH_SMALL}px ${COL2_MENU_WIDTH}px auto ${COL2_MENU_WIDTH}px`,
        gridTemplateRows: `auto`,
        gridTemplateAreas: `'menu col2 content col4'`,
      },
      [breakpoints.up('xl')]: {
        display: 'grid',
        gridTemplateColumns: `${MENU_WIDTH_SMALL}px ${COL2_MENU_WIDTH}px auto ${COL2_MENU_WIDTH}px`,
        gridTemplateRows: `auto`,
        gridTemplateAreas: `'menu col2 content col4'`,
      },
    },
    appIcon: {
      display: 'none',
      [breakpoints.up('sm')]: {
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    appIcon2: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: MAIN_COLORS.UI_DARK,
    },
    appLogo: {
      display: 'flex',
    },
    menu: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      gridArea: 'menu',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: MAIN_COLORS.BACKGROUND_DARK_NAV_BARS,
      height: TAB_BAR_HEIGHT_MOBILE,
      [breakpoints.up('sm')]: {
        position: 'relative',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      [breakpoints.up('lg')]: {
        alignItems: 'center',
      },
    },
    staticNavbar: {
      height: 64,
      display: 'flex',
      alignItems: 'center',
      padding: spacing(2),
      borderBottom: BORDER,
      boxShadow: BORDER_BOX_SHADOW,
    },
    col2: {
      backgroundColor: palette.common.white,
      gridArea: 'col2',
      borderRight: BORDER,
      display: 'flex',
      flexDirection: 'column',
      width: LEFT_COLUMN_MENU_WIDTH,
    },
    col4: {
      backgroundColor: palette.common.white,
      gridArea: 'col4',
      display: 'none',
      borderLeft: BORDER,
      [breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    col4Header: {
      height: 64,
      padding: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: BORDER,
      boxShadow: BORDER_BOX_SHADOW,
    },
    content: {
      paddingBottom: TAB_BAR_HEIGHT_MOBILE + 20,
      backgroundColor: palette.common.white,
      gridArea: 'content',
    },
    wrapper: {
      width: '100%',
      margin: '0 auto',
    },
    snack: {
      minWidth: 150,
      backgroundColor: MAIN_COLORS.UI_DARK,
      borderRadius: 12,
      fontSize: 17,
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: '0.3px',
      justifyContent: 'center',
    },
    patientTestRoot: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '28px 36px',
    },
    patientTestLogoContainer: {
      position: 'absolute',
      top: 28,
      left: 36,
    },
    appBar: {
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.07)',
      justifyContent: 'space-between',
      backgroundColor: MAIN_COLORS.BACKGROUND_LIGHT,
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 16,
      minHeight: 64,
    },
    appBarButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
);
