import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiBackdrop: {
    // root: {
    //   backgroundColor: 'rgba(0,0,0,0.25)',
    // },
  },
  MuiDialogTitle: {
    // root: {
    //   padding: '18px 16px 14px',
    //   '&.center': {
    //     textAlign: 'center',
    //   },
    // },
  },
  MuiDialogContent: {
    // root: {
    //   padding: 0,
    // },
  },
  MuiDialogContentText: {
    // root: {
    //   '&.center': {
    //     textAlign: 'center',
    //   },
    // },
  },
  MuiDialogActions: {
    // root: {
    //   justifyContent: 'center',
    //   padding: '0 12px',
    //   '& > button': {
    //     marginLeft: 8,
    //     marginRight: 8,
    //   },
    // },
  },
};

export const {
  MuiDialogTitle,
  MuiDialogContentText,
  MuiDialogActions,
  MuiBackdrop,
  MuiDialogContent,
} = overrides;
