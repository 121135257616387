import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { BORDER } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    '& path': {
      stroke: MAIN_COLORS.CONTROLS_MUTED,
    },
    color: MAIN_COLORS.UI_DARK,
    '&.selected': {
      color: MAIN_COLORS.UI_LIGHT,
      backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
      '& path': {
        stroke: MAIN_COLORS.UI_LIGHT,
      },
      '&:hover': {
        backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
      },
    },
    '&.onClick': {
      '&:hover': {
        backgroundColor: MAIN_COLORS.CONTROLS_SHAPE_HOVER,
      },
    },
  },
  container: {
    height: 56,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: spacing(2),
    marginRight: spacing(2),
    borderBottom: BORDER,
    '&.last': {
      borderBottom: 0,
    },
    '&.slim': {
      height: 48,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  subtitle: {
    color: MAIN_COLORS.UI_MUTED_TEXT,
    '&.selected': {
      color: MAIN_COLORS.UI_LIGHT,
    },
  },
  disclosure: {
    display: 'flex',
    alignItems: 'center',
  },
  counter: {
    padding: '2px 8px',
    borderRadius: 10,
    color: MAIN_COLORS.UI_LIGHT,
    backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
    '&.selected': {
      color: MAIN_COLORS.CONTROLS_ACTIVE,
      backgroundColor: MAIN_COLORS.UI_LIGHT,
    },
  },
  action: {
    color: MAIN_COLORS.CONTROLS_ACTIVE,
    '&.selected': {
      color: MAIN_COLORS.UI_LIGHT,
    },
  },
}));
