export enum MAIN_COLORS {
  CONTROLS_ACTIVE = '#00B595',
  // CONTROLS_ACTIVE_HOVER = 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00B595;',
  CONTROLS_ACTIVE_HOVER = '#029279',
  CONTROLS_CRITICAL = '#F52727',
  CONTROLS_CRITICAL_HOVER = '#C41F1F',
  CONTROLS_MUTED = '#8D8D8D',
  CONTROLS_MUTED_HOVER = '#8D8D8D',
  CONTROLS_SHAPE = '#F2F2F2',
  CONTROLS_SHAPE_HOVER = '#EBEBEB',
  CONTROLS_SWITCH_SELECTED = '#505050',
  CONTROLS_DISABLED = '#B4B4B4',
  CONTROLS_SHAPE_DISABLED = '#F2F2F2',

  UI_CRITICAL = '#F52727',
  UI_CALM = '#23CD34',
  UI_DARK = '#000000',
  UI_READABLE_TEXT = '#505050',
  UI_MUTED_TEXT = '#8D8D8D',
  UI_NEUTRAL = '#8D8D8D',
  UI_BORDER = '#E4E4E4',
  UI_LIGHT = '#FFFFFF',

  GRAPH_DRAW_LINE = '#F52727',
  GRAPH_PARAM1 = '#8F24D0',
  GRAPH_PARAM1_LIGHT = '#CF75F1',
  GRAPH_PARAM2 = '#FF730F',
  GRAPH_PARAM2_LIGHT = '#FFBC5E',
  GRAPH_PARAM3 = '#00B595',
  GRAPH_PARAM4 = '#1944DA',
  GRAPH_PARAM5 = '#C42B87',
  GRAPH_TEST_MARK = '#E4E4E4',
  GRAPH_TEST_MARK_CURRENT = '#C0C0C0',
  GRAPH_LEGEND_LABEL_BACKGROUND = 'rgba(255, 255, 255, 0.75);',
  GRAPH_DATE_LABEL = '#8D8D8D',
  GRAPH_CURRENT_DATE_LABEL = '#000000',

  BACKGROUND_LIGHT = '#FFFFFF',
  BACKGROUND_LIGHT_NAV_BARS = 'rgba(255, 255, 255, 0.93);',
  BACKGROUND_LIGHTER_GREY = '#F2F2F2',
  BACKGROUND_SELECTED_ITEM = '#C0C0C0',
  BACKGROUND_DARK_NAV_BARS = '#2B2B2B',
  BACKGROUND_PERSISTENT_NOTIFICATION = '#505050',
  BACKGROUND_TIMER_NOTIFICATION = '#000000',
  BACKGROUND_INPUT_WRONG = 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F52727;',

  ADDITIONAL_STRAWBERRY_LIGHT = '#FD7878',
  ADDITIONAL_ORANGE_LIGHT = '#FFBC5E',
  ADDITIONAL_LEMON_LIGHT = '#FEEF6D',
  ADDITIONAL_KIWI_LIGHT = '#74F085',
  ADDITIONAL_POCELAIN_LIGHT = '#4CE5D3',
  ADDITIONAL_SKY_LIGHT = '#5ACFF4',
  ADDITIONAL_BLUEBERRY_LIGHT = '#6995F4',
  ADDITIONAL_FIG_LIGHT = '#CF75F1',
  ADDITIONAL_CHERRY_LIGHT = '#EC7CCA',

  TRANSPARENT = 'transparent',
}
