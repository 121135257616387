import React, { CSSProperties, FC } from 'react';
import cx from 'clsx';

import { useStyles } from './styles';

interface Props {
  bold?: boolean;
  variant?: 'normal' | 'small' | 'micro' | 'nano';
  className?: string;
  style?: CSSProperties;
  dataTest?: string;
}

export const Label: FC<Props> = ({
  children,
  variant = 'normal',
  className,
  style,
  bold = false,
  dataTest,
}) => {
  const css = useStyles();

  return (
    <div
      className={cx(css.label, { bold, [variant]: true }, className)}
      style={style}
      data-test={dataTest}
    >
      {children}
    </div>
  );
};
