import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

export const breakpoints: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
  },
};
