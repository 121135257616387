import React, { FC } from 'react';
import cx from 'clsx';

import { useStyles } from './styles';

interface Props {
  icon?: JSX.Element;
  text: string | JSX.Element;
  onClick: () => void;
  selected?: boolean;
  stress?: boolean;
  muted?: boolean;
  disabled?: boolean;
  dataTest?: string;
}

export const ListItemAction: FC<Props> = ({
  icon,
  text,
  onClick,
  selected = false,
  stress,
  muted,
  disabled,
  dataTest,
}) => {
  const css = useStyles();

  return (
    <div
      className={cx(css.root, { selected, onClick, stress, muted, disabled })}
      onClick={disabled ? undefined : onClick}
      data-test={dataTest}
    >
      <div className={cx(css.container)}>
        {icon && <div className={css.icon}>{icon}</div>}
        <div className={css.text}>{text}</div>
      </div>
    </div>
  );
};
