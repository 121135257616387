import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiTooltip: {
    // tooltip: {
    //   fontSize: 16,
    // },
  },
};

export const { MuiTooltip } = overrides;
