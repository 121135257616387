import React, { FC, ChangeEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@material-ui/core';

import {
  Link,
  ListItemAction,
  ListItemThick,
  IOSSwitch,
  AugmentedDialog,
  Button,
} from 'components/ui';

import { editUser, logout, deleteAccount } from 'utils/api';
import { PATHS } from 'utils/paths';

import { useStyles } from './styles';

interface Props {
  user: User;
}

export const SettingsList: FC<Props> = ({ user }) => {
  const css = useStyles();
  const router = useRouter();

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);

  const handleLogout = async () => {
    await logout();
    router.push('/');
  };

  const handleDeleteAccount = async () => {
    await deleteAccount();
    router.push('/');
  };

  const onToggleReportEmails = async (
    e: ChangeEvent<HTMLInputElement>,
    changed: boolean
  ) => {
    await editUser({
      report_emails: changed,
    });
  };

  return user ? (
    <div className={css.root}>
      <Link href={PATHS.SETTINGS_PROFILE}>
        <ListItemThick
          title={`${user.profile.prefix} ${user.first_name} ${user.last_name}`}
          subtitle={user.email}
          onClick={() => null}
          disclosure
          selected={router.pathname === PATHS.SETTINGS_PROFILE}
          last
        />
      </Link>

      <div className={css.list}>
        <Link href={PATHS.SETTINGS_PLAN_AND_BILLING}>
          <ListItemThick
            title="Plan and Billing"
            disclosure
            onClick={() => null}
            slim
            selected={router.pathname === PATHS.SETTINGS_PLAN_AND_BILLING}
          />
        </Link>
        {/* <ListItemThick
          title="Language"
          disclosure
          disclosureValue="Auto"
          onClick={() => null}
          slim
        /> */}
        <ListItemThick
          title="Report Emails"
          disclosureValue={
            <IOSSwitch
              onChange={onToggleReportEmails}
              checked={user.profile.report_emails || false}
            />
          }
          slim
        />
        <ListItemAction text="Log Out" onClick={handleLogout} stress />

        <div className={css.logout}>
          <ListItemAction
            text="Delete Account..."
            onClick={() => setShowDeleteAccountDialog(true)}
            muted
            dataTest="delete_account"
          />
        </div>
      </div>

      <AugmentedDialog
        open={showDeleteAccountDialog}
        onClose={() => setShowDeleteAccountDialog(false)}
        title="Delete Account"
      >
        <Typography>
          All information linked to this account will be deleted permanentely
          and couldn’t be restored.
        </Typography>
        <Button
          variant="primaryCritical"
          onClick={handleDeleteAccount}
          style={{ width: '100%', marginTop: 16 }}
          data-test="delete_account_confirm"
        >
          Delete
        </Button>
        <Button
          variant="default"
          onClick={() => setShowDeleteAccountDialog(false)}
          style={{ width: '100%', marginTop: 16 }}
        >
          Cancel
        </Button>
      </AugmentedDialog>
    </div>
  ) : null;
};
