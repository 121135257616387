import React, { FC, HTMLAttributes } from 'react';
import cx from 'clsx';

import { Label } from 'components/ui';

import { useStyles } from './styles';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit';
  disabled?: boolean;
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'muted'
    | 'secondaryCritical'
    | 'primaryInverse'
    | 'secondaryInverse'
    | 'primaryCritical';
  name?: string;
  slim?: boolean;
  extraslim?: boolean;
}

export const Button: FC<Props> = ({
  variant = 'primary',
  className,
  children,
  type = 'button',
  slim,
  extraslim,
  ...rest
}) => {
  const css = useStyles();

  return (
    <button
      {...rest}
      type={type}
      className={cx(css.root, css.button, css[variant], className, {
        slim,
        extraslim,
      })}
    >
      <Label
        style={{ display: 'flex', alignItems: 'center', fontWeight: 'inherit' }}
      >
        {children}
      </Label>
    </button>
  );
};
