import { makeStyles } from '@material-ui/styles';

import { FONT_WEIGHT_LABEL } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles({
  label: {
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    fontStyle: 'normal',
    fontWeight: FONT_WEIGHT_LABEL,
    '&.normal': {
      fontSize: 17,
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: '0.3px',
      '&.bold': {
        letterSpacing: '0.1px',
      },
    },
    '&.small': {
      fontSize: 13.2,
      lineHeight: '16px',
      letterSpacing: '0.4px',
      '&.bold': {
        letterSpacing: '0.35px',
      },
    },
    '&.micro': {
      fontSize: 11,
      lineHeight: '12px',
      letterSpacing: '0.4px',
    },
    '&.nano': {
      fontSize: 9,
      lineHeight: '12px',
      letterSpacing: '1px',
    },
    '&.bold': {
      fontWeight: 800,
    },
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
});
