import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiAvatar: {
    // root: {
    //   display: 'inline-flex',
    //   verticalAlign: 'middle',
    //   minWidth: 40,
    // },
  },
};

export const { MuiAvatar } = overrides;
