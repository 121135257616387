import React, { FC, useState, useEffect } from 'react';
import useSWR from 'swr';
import { Typography } from '@material-ui/core';
import { useRouter } from 'next/router';

import { Link, ListItemThick } from 'components/ui';
import {
  ListItemAction,
  ButtonRound,
  AddPatientDialog,
  ListItemBasic,
} from 'components/ui';
import { IconAdd } from 'components/ui/icons/IconAdd';

import { getPatients, getPlans } from 'utils/api';

import { useStyles } from './styles';
import { PATHS } from 'utils/paths';

interface Props {
  patients: Patient[];
  user: User;
}

export const PatientsList: FC<Props> = ({ patients = [], user }) => {
  const css = useStyles();
  const router = useRouter();

  const { data } = useSWR('/api/patients/', getPatients, {
    fallbackData: patients,
  });

  const { data: plans = [] } = useSWR('/api/plans/', getPlans);

  const currentPlan = plans.find((p) => p.id === user?.profile.current_plan);

  const regular = data.filter((p) => !p.is_archived);
  const archived = data.filter((p) => p.is_archived);

  const [selected, setSelected] = useState<string>();
  const [showArchived, setShowArchived] = useState('hide');
  const [add, setAdd] = useState(false);

  useEffect(() => {
    setShowArchived(localStorage.getItem('showArchivedPatients'));
    if (router.query.observable) {
      setSelected(router.query.observable as string);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('showArchivedPatients', showArchived);
  }, [showArchived]);

  const disabledAddPatient =
    user?.profile?.active_patients >= currentPlan?.max_seats;

  return (
    <div className={css.root}>
      <AddPatientDialog open={add} setOpen={setAdd} />

      <ListItemAction
        icon={
          <ButtonRound sm disabled={disabledAddPatient}>
            <IconAdd />
          </ButtonRound>
        }
        text="Add observable..."
        onClick={() => setAdd(true)}
        selected={selected === 'add'}
        disabled={disabledAddPatient}
        dataTest="add-observable"
      />

      {regular
        .sort((a, b) => b.id - a.id)
        .map((p, i) => (
          <div key={p.id}>
            <Link
              href={PATHS.OBSERVABLE}
              as={`${PATHS.OBSERVABLES}/${p.hash_url}`}
            >
              <ListItemThick
                title={p.name}
                subtitle={
                  <>
                    {p.gender} {p.age ? `• ${p.age}` : null}
                  </>
                }
                onClick={() => setSelected(p.hash_url)}
                disclosure
                selected={selected === p.hash_url}
                last={i === regular.length - 1}
                counter={p.unread}
                dataTest={`observable-${p.name}`}
              />
            </Link>
          </div>
        ))}

      {archived.length ? (
        <>
          {showArchived === 'show' ? (
            <div style={{ paddingTop: 32 }}>
              <div
                style={{ paddingLeft: 16, paddingBottom: 12, paddingTop: 12 }}
              >
                <Typography variant="h4">Archived Profiles</Typography>
              </div>
              {archived
                .sort((a, b) => b.id - a.id)
                .map((p, i) => (
                  <div key={p.id}>
                    <Link
                      href={PATHS.OBSERVABLE}
                      as={`${PATHS.OBSERVABLES}/${p.hash_url}`}
                    >
                      <ListItemThick
                        title={p.name}
                        subtitle={
                          <>
                            {p.gender} {p.age && `• ${p.age}`}
                          </>
                        }
                        onClick={() => setSelected(p.hash_url)}
                        // counter={5}
                        disclosure
                        selected={selected === p.hash_url}
                        last={i === archived.length - 1}
                        dataTest={`observable-${p.name}`}
                      />
                    </Link>
                  </div>
                ))}
              <ListItemAction
                text="Hide Archived"
                onClick={() => setShowArchived('hide')}
              />
            </div>
          ) : (
            <div style={{ paddingTop: 32 }}>
              <ListItemBasic
                title="Show Archived"
                onClick={() => setShowArchived('show')}
                last
                dataTest="show-archived"
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
