import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import {
  BODY_FONT_SIZE,
  CAPTION_FONT_SIZE,
  FONT_FAMILY,
  BODY_LINE_HEIGHT,
  FONT_WEIGHT_NORMAL,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_LABEL,
} from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

export const typography: TypographyOptions = {
  fontFamily: FONT_FAMILY,
  // Header Big
  h1: {
    fontSize: 36,
    lineHeight: '48px',
    letterSpacing: '-0.3px',
    fontWeight: FONT_WEIGHT_BOLD,
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Header Normal
  h2: {
    fontSize: 28,
    lineHeight: '40px',
    fontWeight: FONT_WEIGHT_BOLD,
    letterSpacing: '-0.2px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Header Small
  h3: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: FONT_WEIGHT_BOLD,
    letterSpacing: '-0.1px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Header Micro
  h4: {
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: FONT_WEIGHT_BOLD,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Text Normal
  body1: {
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    fontWeight: FONT_WEIGHT_NORMAL,
    wordBreak: 'break-word',
    letterSpacing: '0.3px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Text Important
  body2: {
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    fontWeight: FONT_WEIGHT_BOLD,
    wordBreak: 'break-word',
    letterSpacing: '0.1px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Text Small
  caption: {
    fontSize: CAPTION_FONT_SIZE,
    lineHeight: '20px',
    fontWeight: FONT_WEIGHT_LABEL,
    letterSpacing: '0.4px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
  // Text Micro
  overline: {
    fontSize: 11,
    lineHeight: '16px',
    fontWeight: FONT_WEIGHT_LABEL,
    letterSpacing: '0.4px',
    '&.muted': {
      color: MAIN_COLORS.UI_MUTED_TEXT,
    },
  },
};
