export const PatientsTab = ({ active }: { active: boolean }) =>
  active ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="16.0001"
        cy="4.0001"
        rx="2.4"
        ry="2.4"
        stroke="#00B595"
        strokeWidth="2"
      />
      <path
        d="M27.1998 27.925V8.87495C27.1998 7.96401 27.1998 7.50853 27.0086 7.16702C26.8734 6.92566 26.6741 6.72638 26.4327 6.59121C26.0912 6.39995 25.6358 6.39995 24.7248 6.39995H7.2748C6.36386 6.39995 5.90839 6.39995 5.56687 6.59121C5.32551 6.72638 5.12623 6.92566 4.99106 7.16702C4.7998 7.50853 4.7998 7.96401 4.7998 8.87495V27.925C4.7998 28.8359 4.7998 29.2914 4.99106 29.6329C5.12623 29.8743 5.32551 30.0735 5.56687 30.2087C5.90839 30.4 6.36386 30.4 7.2748 30.4H24.7248C25.6358 30.4 26.0912 30.4 26.4327 30.2087C26.6741 30.0735 26.8734 29.8743 27.0086 29.6329C27.1998 29.2914 27.1998 28.8359 27.1998 27.925Z"
        stroke="#00B595"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1998 8.8749V27.9249C27.1998 28.8359 27.1998 29.2913 27.0086 29.6328C26.8734 29.8742 26.6741 30.0735 26.4327 30.2086C26.0912 30.3999 25.6358 30.3999 24.7248 30.3999H7.2748C6.36386 30.3999 5.90839 30.3999 5.56687 30.2086C5.32551 30.0735 5.12623 29.8742 4.99106 29.6328C4.7998 29.2913 4.7998 28.8359 4.7998 27.9249V8.8749C4.7998 7.96396 4.7998 7.50848 4.99106 7.16697C5.12623 6.92561 5.32551 6.72633 5.56687 6.59116C5.90839 6.3999 6.36386 6.3999 7.2748 6.3999H24.7248C25.6358 6.3999 26.0912 6.3999 26.4327 6.59116C26.6741 6.72633 26.8734 6.92561 27.0086 7.16697C27.1998 7.50848 27.1998 7.96396 27.1998 8.8749ZM11.1998 12.5999C10.6475 12.5999 10.1998 13.0476 10.1998 13.5999C10.1998 14.1522 10.6475 14.5999 11.1998 14.5999H20.7998C21.3521 14.5999 21.7998 14.1522 21.7998 13.5999C21.7998 13.0476 21.3521 12.5999 20.7998 12.5999H11.1998ZM10.1998 18.3999C10.1998 17.8476 10.6475 17.3999 11.1998 17.3999H20.7998C21.3521 17.3999 21.7998 17.8476 21.7998 18.3999C21.7998 18.9522 21.3521 19.3999 20.7998 19.3999H11.1998C10.6475 19.3999 10.1998 18.9522 10.1998 18.3999ZM11.1998 22.1999C10.6475 22.1999 10.1998 22.6476 10.1998 23.1999C10.1998 23.7522 10.6475 24.1999 11.1998 24.1999H17.5998C18.1521 24.1999 18.5998 23.7522 18.5998 23.1999C18.5998 22.6476 18.1521 22.1999 17.5998 22.1999H11.1998Z"
        fill="#00B595"
      />
      <path
        d="M10.3999 6.39995V4.19995C10.3999 3.64767 10.8476 3.19995 11.3999 3.19995H13.5999V6.39995H18.3999V3.19995H20.5999C21.1522 3.19995 21.5999 3.64767 21.5999 4.19995V6.39995H18.3999H13.5999H10.3999Z"
        fill="#00B595"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3999 6.3999H21.5999" stroke="white" strokeWidth="2" />
      <path
        d="M10.3999 6.39995V4.19995C10.3999 3.64767 10.8476 3.19995 11.3999 3.19995H13.5999V6.39995H18.3999V3.19995H20.5999C21.1522 3.19995 21.5999 3.64767 21.5999 4.19995V6.39995H18.3999H13.5999H10.3999Z"
        fill="white"
      />
      <ellipse
        cx="16.0001"
        cy="4.0001"
        rx="2.4"
        ry="2.4"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M8.79976 6.3999H7.2748C6.36386 6.3999 5.90838 6.3999 5.56687 6.59116C5.32551 6.72633 5.12623 6.92561 4.99106 7.16697C4.7998 7.50848 4.7998 7.96396 4.7998 8.8749V27.9249C4.7998 28.8358 4.7998 29.2913 4.99106 29.6328C5.12623 29.8742 5.32551 30.0735 5.56687 30.2086C5.90838 30.3999 6.36386 30.3999 7.2748 30.3999H24.7248C25.6358 30.3999 26.0912 30.3999 26.4327 30.2086C26.6741 30.0735 26.8734 29.8742 27.0086 29.6328C27.1998 29.2913 27.1998 28.8358 27.1998 27.9249V8.87487C27.1998 7.96393 27.1998 7.50847 27.0086 7.16696C26.8734 6.9256 26.6741 6.72632 26.4327 6.59115C26.0912 6.39989 25.6358 6.39988 24.7248 6.39987L23.1998 6.39985"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.2002 13.6001L20.8002 13.6001"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2002 18.3999H20.8002"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2002 23.2H17.6002"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export const SettingsTab = ({ active }: { active: boolean }) =>
  active ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3993 2.87734C17.2531 1.17422 14.7469 1.17422 13.6008 2.87734L12.7924 4.07852C12.1433 5.04316 10.9795 5.52521 9.83837 5.30214L8.41741 5.02436C6.40269 4.63051 4.63053 6.40267 5.02438 8.41739L5.30216 9.83835C5.52524 10.9795 5.04319 12.1432 4.07854 12.7924L2.87736 13.6008C1.17424 14.7469 1.17424 17.2531 2.87736 18.3992L4.07854 19.2076C5.04319 19.8567 5.52524 21.0205 5.30216 22.1617L5.02438 23.5826C4.63053 25.5973 6.40269 27.3695 8.41741 26.9756L9.83837 26.6979C10.9795 26.4748 12.1433 26.9568 12.7924 27.9215L13.6008 29.1227C14.7469 30.8258 17.2531 30.8258 18.3993 29.1227L19.2076 27.9215C19.8568 26.9568 21.0205 26.4748 22.1617 26.6979L23.5826 26.9756C25.5973 27.3695 27.3695 25.5973 26.9757 23.5826L26.6979 22.1617C26.4748 21.0205 26.9569 19.8567 27.9215 19.2076L29.1227 18.3992C30.8258 17.2531 30.8258 14.7469 29.1227 13.6008L27.9215 12.7924C26.9569 12.1432 26.4748 10.9795 26.6979 9.83835L26.9757 8.41739C27.3695 6.40267 25.5973 4.63051 23.5826 5.02436L22.1617 5.30214C21.0205 5.52521 19.8568 5.04316 19.2076 4.07852L18.3993 2.87734ZM16 20.8018C18.651 20.8018 20.8 18.6519 20.8 16C20.8 13.348 18.651 11.1982 16 11.1982C13.349 11.1982 11.2 13.348 11.2 16C11.2 18.6519 13.349 20.8018 16 20.8018Z"
        fill="#00B595"
      />
      <path
        d="M13.6008 2.87734C14.7469 1.17422 17.2531 1.17422 18.3992 2.87734L19.2076 4.07852C19.8568 5.04316 21.0205 5.52521 22.1617 5.30214L23.5826 5.02436C25.5973 4.63051 27.3695 6.40267 26.9756 8.41739L26.6979 9.83835C26.4748 10.9795 26.9568 12.1432 27.9215 12.7924L29.1227 13.6008C30.8258 14.7469 30.8258 17.2531 29.1227 18.3992L27.9215 19.2076C26.9568 19.8567 26.4748 21.0205 26.6979 22.1616L26.9756 23.5826C27.3695 25.5973 25.5973 27.3695 23.5826 26.9756L22.1617 26.6979C21.0205 26.4748 19.8568 26.9568 19.2076 27.9215L18.3992 29.1227C17.2531 30.8258 14.7469 30.8258 13.6008 29.1227L12.7924 27.9215C12.1433 26.9568 10.9795 26.4748 9.83835 26.6979L8.4174 26.9756C6.40268 27.3695 4.63051 25.5973 5.02437 23.5826L5.30214 22.1616C5.52522 21.0205 5.04317 19.8567 4.07853 19.2076L2.87734 18.3992C1.17423 17.2531 1.17423 14.7469 2.87734 13.6008L4.07853 12.7924C5.04317 12.1432 5.52522 10.9795 5.30214 9.83835L5.02437 8.41739C4.63051 6.40267 6.40268 4.63051 8.4174 5.02436L9.83835 5.30214C10.9795 5.52521 12.1433 5.04316 12.7924 4.07852L13.6008 2.87734Z"
        stroke="#00B595"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6008 2.87734C14.7469 1.17422 17.2531 1.17422 18.3992 2.87734L19.2076 4.07852C19.8568 5.04316 21.0205 5.52521 22.1617 5.30214L23.5826 5.02436C25.5973 4.63051 27.3695 6.40267 26.9756 8.41739L26.6979 9.83835C26.4748 10.9795 26.9568 12.1432 27.9215 12.7924L29.1227 13.6008C30.8258 14.7469 30.8258 17.2531 29.1227 18.3992L27.9215 19.2076C26.9568 19.8567 26.4748 21.0205 26.6979 22.1616L26.9756 23.5826C27.3695 25.5973 25.5973 27.3695 23.5826 26.9756L22.1617 26.6979C21.0205 26.4748 19.8568 26.9568 19.2076 27.9215L18.3992 29.1227C17.2531 30.8258 14.7469 30.8258 13.6008 29.1227L12.7924 27.9215C12.1433 26.9568 10.9795 26.4748 9.83835 26.6979L8.4174 26.9756C6.40268 27.3695 4.63051 25.5973 5.02437 23.5826L5.30214 22.1616C5.52522 21.0205 5.04317 19.8567 4.07853 19.2076L2.87734 18.3992C1.17423 17.2531 1.17423 14.7469 2.87734 13.6008L4.07853 12.7924C5.04317 12.1432 5.52522 10.9795 5.30214 9.83835L5.02437 8.41739C4.63051 6.40267 6.40268 4.63051 8.4174 5.02436L9.83835 5.30214C10.9795 5.52521 12.1433 5.04316 12.7924 4.07852L13.6008 2.87734Z"
        stroke="white"
        strokeWidth="2"
      />
      <ellipse
        cx="16"
        cy="16"
        rx="4.8"
        ry="4.80179"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );

export const HelpTab = ({ active }: { active: boolean }) =>
  active ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C20.0584 32 23.7639 30.489 26.5846 27.9988L20.9056 22.3199C19.551 23.3729 17.8487 24 16 24C14.1513 24 12.449 23.3729 11.0944 22.3199L5.41538 27.9988C8.23608 30.489 11.9416 32 16 32ZM4.00117 26.5846L9.68014 20.9056C8.62708 19.551 8 17.8487 8 16C8 14.1513 8.62708 12.449 9.68014 11.0944L4.00117 5.41538C1.51102 8.23608 0 11.9416 0 16C0 20.0584 1.51102 23.7639 4.00117 26.5846ZM16 8C14.1513 8 12.449 8.62708 11.0944 9.68014L5.41538 4.00117C8.23608 1.51102 11.9416 0 16 0C20.0584 0 23.7639 1.51102 26.5846 4.00117L20.9056 9.68014C19.551 8.62708 17.8487 8 16 8ZM27.9988 5.41538L22.3199 11.0944C23.3729 12.449 24 14.1513 24 16C24 17.8487 23.3729 19.551 22.3199 20.9056L27.9988 26.5846C30.489 23.7639 32 20.0584 32 16C32 11.9416 30.489 8.23608 27.9988 5.41538Z"
        fill="#00B595"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.81767 26.1823C11.4412 31.8059 20.5588 31.8059 26.1823 26.1823M5.81767 26.1823C0.194119 20.5588 0.194119 11.4412 5.81767 5.81767M5.81767 26.1823L10.3432 21.6569M26.1823 26.1823C31.8059 20.5588 31.8059 11.4412 26.1823 5.81767M26.1823 26.1823L21.6569 21.6569M5.81767 5.81767C11.4412 0.194119 20.5588 0.194119 26.1823 5.81767M5.81767 5.81767L10.3432 10.3432M26.1823 5.81767L21.6569 10.3432M10.3432 21.6569C13.4673 24.7811 18.5327 24.7811 21.6569 21.6569M10.3432 21.6569C7.21896 18.5327 7.21896 13.4673 10.3432 10.3432M21.6569 21.6569C24.7811 18.5327 24.7811 13.4673 21.6569 10.3432M10.3432 10.3432C13.4673 7.21896 18.5327 7.21896 21.6569 10.3432"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
