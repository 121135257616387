import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { ButtonRound } from 'components/ui';
import { IconClose } from 'components/ui/icons/IconClose';

import { TransitionUp } from 'utils/transitions';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
  closeIcon?: boolean;
}

export const AugmentedDialog: FC<Props> = ({
  open = false,
  onClose,
  title,
  closeIcon,
  children,
}) => {
  const css = useStyles();

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      onClose={onClose}
      classes={{ root: css.root, paper: css.paper }}
      scroll="paper"
    >
      {title ? (
        <DialogTitle className={css.dialogTitle}>
          <div className={css.info}>
            <div>
              <Typography variant="h3">{title}</Typography>
            </div>
            {closeIcon && (
              <ButtonRound onClick={onClose} data-test="dialog-close">
                <IconClose lg />
              </ButtonRound>
            )}
          </div>
        </DialogTitle>
      ) : null}

      <DialogContent className={css.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};
