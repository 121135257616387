import { Overrides } from '@material-ui/core/styles/overrides';
import { BORDER } from 'utils/constants';

const overrides: Overrides = {
  MuiList: {
    // padding: {
    //   paddingTop: 100,
    //   paddingBottom: 106,
    //   paddingLeft: 16,
    //   paddingRight: 16,
    // },
  },
  MuiListItem: {
    // root: {
    //   paddingTop: 12,
    //   paddingBottom: 12,
    //   borderBottom: BORDER,
    //   '&:last-child': {
    //     borderBottom: 'none',
    //   },
    // },
    // gutters: {
    //   paddingLeft: 0,
    //   paddingRight: 0,
    // },
  },
};

export const { MuiList, MuiListItem } = overrides;
