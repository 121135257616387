import React, { FC, useState } from 'react';
import { Typography } from '@material-ui/core';
import cx from 'clsx';
import useSWR from 'swr';

import { Label, AugmentedDialog, ListItemBasic } from 'components/ui';

import { getTestDate, testsSortFn } from 'utils';
import { editTest, getTests } from 'utils/api';
import { BORDER, PUBLIC_URL } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';

import { useStyles } from './styles';

const getImageUrl = (url: string) => {
  if (url === undefined) return;
  return process.env.NODE_ENV === 'development'
    ? url.replace(/https?:\/\/localhost/, PUBLIC_URL)
    : url.replace(/http/, 'https');
};

interface Props {
  patientId: number;
  onPatientTestView: (patientId: number) => void;
}

export const TestsList: FC<Props> = ({ patientId, onPatientTestView }) => {
  const css = useStyles();

  const [showTestDialog, setShowTestDialog] = useState(false);
  const [selectedTest, setSelectedTest] = useState<Test | undefined>();

  const {
    data: tests = [],
    mutate,
    isValidating,
  } = useSWR(['/api/tests/', patientId], (url, patientId) =>
    getTests(patientId)
  );

  const markViewed = async (test: TestEditPayload) => {
    if (!test.is_viewed) {
      const newTest = await editTest({
        id: test.id,
        is_viewed: true,
        patient: patientId,
      });

      mutate(
        tests.map((test) => (test.id === newTest.id ? newTest : test)),
        false
      );

      onPatientTestView(patientId);
    }
  };

  return (
    <div className={css.root}>
      {tests.length ? (
        <>
          <div className={css.listHeader}>
            <Typography variant="h4" className={css.listHeaderCol1}>
              Tests
            </Typography>
            <Typography variant="h4" className={css.listHeaderCol2}>
              Date
            </Typography>
            <Typography
              variant="h4"
              className={css.listHeaderCol3}
              style={{ color: MAIN_COLORS.GRAPH_PARAM1 }}
            >
              DR
            </Typography>
            <Typography
              variant="h4"
              className={css.listHeaderCol4}
              style={{ color: MAIN_COLORS.GRAPH_PARAM4 }}
            >
              MD
            </Typography>
          </div>
          {tests.sort(testsSortFn).map((t, i) => (
            <div key={t.hash_id}>
              <div
                className={cx(css.listRoot, { new: !t.is_viewed })}
                key={t.hash_id}
                onClick={() => {
                  markViewed(t);
                  setSelectedTest(t);
                  setShowTestDialog(true);
                }}
              >
                <div className={css.list}>
                  {t.user_path_img && (
                    <div className={css.listCol1}>
                      <img width="48" src={getImageUrl(t.user_path_img)} />
                    </div>
                  )}
                  <div className={css.listCol2}>
                    <Label>{getTestDate(t.created_at)}</Label>
                  </div>
                  <div className={css.listCol3}>
                    <Label dataTest="distance">
                      {t.distance_ratio.toFixed(2)}
                    </Label>
                  </div>
                  <div className={css.listCol4}>
                    <Label>{t.mean_deviation.toFixed(1)}</Label>
                  </div>
                </div>

                {i !== tests.length - 1 && (
                  <div
                    style={{
                      borderBottom: BORDER,
                      marginLeft: 64 + 16,
                      marginRight: 16,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </>
      ) : isValidating ? null : (
        <div className={css.noTests}>
          <Typography variant="h3">There Are No Tests To Show Yet</Typography>

          <Typography>
            1. Copy the link above and send it to your observable so he can
            perform the test.
          </Typography>

          <Typography>
            2. Once the test will be done, its result will appear here and you
            will see some analytics.
          </Typography>

          <Typography>
            3. After few test will be performed, you will find dynamics of it’s
            properties.
          </Typography>
        </div>
      )}

      <AugmentedDialog
        closeIcon={true}
        open={showTestDialog}
        onClose={() => {
          setShowTestDialog(false);
          setSelectedTest(undefined);
        }}
        title={getTestDate(selectedTest?.created_at)}
      >
        {selectedTest && (
          <>
            {selectedTest.user_path_img && (
              <div className={css.testImageContainer}>
                <img
                  width="100%"
                  src={getImageUrl(selectedTest.user_path_img)}
                  className={css.testImage}
                />
              </div>
            )}
            <div className={css.testParamsList}>
              <ListItemBasic
                title="Test time"
                value={(selectedTest.time / 1000).toFixed(1) + 's'}
              />
              <ListItemBasic
                title="Distance ratio"
                value={selectedTest.distance_ratio.toFixed(2)}
              />
              <ListItemBasic
                title="Deviations"
                value={String(selectedTest.deviations)}
                dataTest="deviations"
              />
              <ListItemBasic
                title="Deviations sum"
                value={selectedTest.deviations_sum.toFixed(1)}
              />
              <ListItemBasic
                title="Mean deviation"
                value={selectedTest.mean_deviation.toFixed(1)}
                last
              />
              {/* <ListItemBasic
                title="velocity_param_1"
                value={selectedTest.velocity_param_1.toFixed(3)}
              />
              <ListItemBasic
                title="velocity_param_2"
                value={selectedTest.velocity_param_2.toFixed(3)}
                last
              /> */}
            </div>

            {selectedTest.straight_path_img && (
              <>
                <h3>Distance ratio</h3>
                <div className={css.testImageContainer}>
                  <img
                    width="100%"
                    src={getImageUrl(selectedTest.straight_path_img)}
                    className={css.testImage}
                  />
                </div>
              </>
            )}
            <div className={css.dataParamsList}>
              <ListItemBasic
                title="Distance ratio"
                value={selectedTest.distance_ratio.toFixed(2)}
                last
              />
            </div>
            <div className={css.dataExplanation}>
              <Typography>
                The ratio (𝑑∕𝑟) of the drawn spiral length (𝑑) to the reference
                spiral length (𝑟). Both spirals are pre-straightened. The more
                distorted the drawn spiral, the greater the distance ratio. The
                smallest possible value is 1, which means that both spirals are
                equal.
              </Typography>
            </div>

            {selectedTest.deviations_img && (
              <>
                <h3>Deviations</h3>
                <div className={css.testImageContainer}>
                  <img
                    width="100%"
                    src={getImageUrl(selectedTest.deviations_img)}
                    className={css.testImage}
                  />
                </div>
              </>
            )}
            <div className={css.dataParamsList}>
              <ListItemBasic
                title="Deviations"
                value={String(selectedTest.deviations)}
                last
              />
            </div>
            <div className={css.dataExplanation}>
              <Typography>
                The count of path fluctuations of the straightened drawn spiral
                alongside the direction of drawing. The more distorted spiral
                has more deviations.
              </Typography>
            </div>

            {selectedTest.deviations_sum_img && (
              <>
                <h3>Deviations sum</h3>
                <div className={css.testImageContainer}>
                  <img
                    width="100%"
                    src={getImageUrl(selectedTest.deviations_sum_img)}
                    className={css.testImage}
                  />
                </div>
              </>
            )}
            <div className={css.dataParamsList}>
              <ListItemBasic
                title="Deviations sum"
                value={selectedTest.deviations_sum.toFixed(1)}
              />
              <ListItemBasic
                title="Mean deviation"
                value={selectedTest.mean_deviation.toFixed(1)}
                last
              />
            </div>
            <div className={css.dataExplanation}>
              <Typography>
                The sum of the deviations. The minimum possible value is 0 and
                means that the drawn and reference spirals are equal. The more
                significant distortions the drawn spiral has, the greater the
                sum of the deviations. The mean deviation is the ratio of the
                sum of deviations to the number of deviations.
              </Typography>
            </div>
            {selectedTest.deviations_sum_img && (
              <>
                <h3>Fourier transform</h3>
                <div className={css.testImageContainer}>
                  <img
                    width="100%"
                    src={getImageUrl(selectedTest.fft_x_img)}
                    className={css.testImage}
                  />
                </div>
              </>
            )}
            <div className={css.dataParamsList}></div>
            <div className={css.dataExplanation}>
              <Typography>
                The Fourier transform is applied to the drawn path to detect
                fluctuations with a constant frequency. The x-axis represents
                the frequency from 1 to 10 Hz. The y-axis is the amplitude of
                the oscillation.
              </Typography>
            </div>
            {/* <h3>Velocity</h3>
            <div className={css.testImageContainer}>
              <img width="100%" src={getImageUrl(selectedTest.velocity_img)} />
            </div>
            <div className={css.dataParamsList}>
              <ListItemBasic
                title="Param 1"
                value={selectedTest.velocity_param_1.toFixed(3)}
              />
              <ListItemBasic
                title="Param 2"
                value={selectedTest.velocity_param_2.toFixed(3)}
                last
              />
            </div>
            <div className={css.dataExplanation}>
              <Typography>TODO: Description.</Typography>
            </div> */}
          </>
        )}
      </AugmentedDialog>
    </div>
  );
};
