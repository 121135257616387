export enum Color {
  white = '#ffffff',
  primaryLight = 'rgba(201, 17, 91, 0.1)',
  primaryMain = '#00B595',
  primaryDark = '#920032',
  primaryContrastText = '#ffffff',

  secondaryLight = '#FCEEF4',
  secondaryMain = '#F2F2F2',
  secondaryDark = '#FCEEF4',
  secondaryContrastText = '#C9115B',

  textPrimary = '#000000',
  textSecondary = '#A3A3A3',
  textHint = 'rgba(0, 0, 0, 0.38)',
  textDisabled = 'rgba(0, 0, 0, 0.38)',

  errorLight = 'rgba(234, 38, 26, 0.2)',
  errorMain = '#EA261A',
  errorDark = 'red',
  errorContrastText = '#ffffff',

  backgroundDefault = '#FFFFFF',

  divider = '#C9C9C9',
  border = '#C0C0C0',
}
