import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  list: {
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
  },
  button: {
    width: '100%',
    marginTop: spacing(2),
  },
}));
