import React, { FC, useState, useEffect } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';

import { AugmentedDialog, Button } from 'components/ui';

import { randomPatientHash } from 'utils';

import { useStyles } from './styles';
import { theme } from 'theme';

interface Props {
  patient: Patient;
  show: boolean;
  onChange: (newPatient: Patient) => void;
  onShowChangeDialog?: () => void;
}

export const ChangePatientLinkConfirm: FC<Props> = ({
  patient,
  show,
  onChange,
}) => {
  const css = useStyles();
  const router = useRouter();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const [showChangeDialog, setShowChangeDialog] = useState(show);

  const changeLink = async () => {
    const newPatient = {
      ...patient,
      hash_url: randomPatientHash(),
    };

    onChange(newPatient);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    if (lg) {
      navigateToLinkEdit();
    }
    setShowChangeDialog(false);
  };

  const navigateToLinkEdit = () => {
    router.push('?action=patient-link-edit', `${patient.hash_url}`, {
      shallow: true,
    });
  };

  useEffect(() => {
    setShowChangeDialog(show);
  }, [show]);

  return (
    <AugmentedDialog
      closeIcon={true}
      open={showChangeDialog}
      onClose={handleCloseDialog}
      title="Change Observables’s Link"
    >
      <Typography>
        Old link will be unaccessable. You will have inform your observable
        about changes.
      </Typography>
      <Button variant="default" className={css.button} onClick={changeLink}>
        Change
      </Button>
      <Button
        variant="default"
        className={css.button}
        onClick={() => {
          navigateToLinkEdit();
          setShowChangeDialog(false);
        }}
      >
        Go Back
      </Button>
    </AugmentedDialog>
  );
};
