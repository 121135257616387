import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import useSWR from 'swr';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';

import { Button, Select } from 'components/ui';

import { getInitialUser } from 'utils/api';

import { useStyles } from './styles';

const genders = ['Unspecified', 'Male', 'Female', 'Intersex'] as const;
type Gender = typeof genders[number];

const now = new Date().getUTCFullYear();
const years = Array(now - (now - 90))
  .fill('')
  .map((v, idx) => now - idx);

interface Props {
  submitText: string;
  onFormSubmit: (p: AddPatientPayload) => void;
  initialPatient?: Patient;
}

export const PatientProfileForm: FC<Props> = ({
  submitText,
  onFormSubmit,
  initialPatient,
}) => {
  const css = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState<string>(initialPatient?.name);
  const [gender, setGender] = useState<Gender>(
    initialPatient?.gender || 'Unspecified'
  );
  const [year, setYear] = useState<number | undefined>(
    initialPatient?.age ? now - initialPatient?.age : undefined
  );
  const [notes, setNotes] = useState(initialPatient?.notes || '');

  const [disabled, setDisabled] = useState(false);

  const { data: user } = useSWR<User>('/api/auth/user/', getInitialUser);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
    setGender(e.target.value as Gender);
  };

  const onChangeYear = (e: ChangeEvent<HTMLSelectElement>) => {
    setYear(Number(e.target.value));
  };

  const onChangeNotes = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  // const formClear = () => {
  //   setName(undefined);
  //   setGender('Unspecified');
  //   setYear(undefined);
  //   setCondition('Unspecified');
  // };

  const onSubmit = async () => {
    setError(!!!name?.length);

    if (name?.length) {
      setLoading(true);
      onFormSubmit({
        ...initialPatient,
        doctor: user.doctor.id,
        name,
        gender,
        age: year ? now - year : 0,
        notes,
      });
      setDisabled(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setError(name !== undefined && !!!name.length);
  }, [name]);

  useEffect(() => {
    setDisabled(
      name === initialPatient?.name &&
        gender === initialPatient?.gender &&
        now - year === initialPatient?.age &&
        notes === initialPatient?.notes
    );
  }, [name, gender, year, notes]);

  return (
    <div>
      <TextField
        id="patient-name"
        label="Name"
        placeholder="John S."
        fullWidth
        className={css.withLabel}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeName}
        value={name || ''}
        error={error}
      />
      <Typography variant="caption">
        It could be ficional name or patinet’s ID from your file. This field is
        mandatory.
      </Typography>

      <Select
        value={gender}
        onChange={onChangeGender}
        propName="gender"
        label="Gender"
        options={genders}
      />

      <Select
        value={String(year)}
        onChange={onChangeYear}
        propName="year"
        label="Year of Birth"
        options={['Unspecified', ...years.map((y) => String(y))]}
      />

      <TextField
        id="patient-notes"
        label="Notes"
        // placeholder="John S."
        fullWidth
        className={css.withLabelTextInput}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeNotes}
        value={notes || ''}
        multiline
        rows={10}
      />

      <div className={css.formControl}>
        <Button
          type="submit"
          className={css.button}
          onClick={onSubmit}
          disabled={loading || disabled}
        >
          {submitText}
        </Button>
      </div>
    </div>
  );
};
