import { Overrides } from '@material-ui/core/styles/overrides';
import { MAIN_COLORS } from 'utils/colors';

const overrides: Overrides = {
  MuiInput: {
    // root: {
    //   caretColor: Color.primaryMain,
    //   '&$focused': {
    //     backgroundColor: Color.primaryLight,
    //   },
    // },
    // input: {
    //   '&.InputNumber': {
    //     textAlign: 'right',
    //   },
    // },
    // inputType: {
    //   '&[type="date"]': {
    //     width: 145,
    //   },
    // },
  },
  MuiInputBase: {
    root: {
      height: 56,
      boxShadow: `inset 0 0 0 1px ${MAIN_COLORS.UI_BORDER}`,
      borderRadius: 12,
      paddingLeft: 16,
      paddingRight: 16,
      caretColor: MAIN_COLORS.CONTROLS_ACTIVE,
      marginTop: '0px !important',
      fontWeight: 400,
      '& > .MuiSvgIcon-root': {
        display: 'none',
      },
      '&.MuiInput-multiline': {
        height: 'auto',
      },
      '&.Mui-disabled': {
        boxShadow: 'none',
        fontWeight: 800,
      },
      '&.Mui-focused': {
        fontWeight: 800,
        boxShadow: `inset 0 0 0 2px ${MAIN_COLORS.CONTROLS_ACTIVE}`,
      },
      '&.Mui-error': {
        color: MAIN_COLORS.CONTROLS_CRITICAL,
        boxShadow: `inset 0 0 0 1px ${MAIN_COLORS.CONTROLS_CRITICAL}`,
        '&.Mui-focused': {
          boxShadow: `inset 0 0 0 2px ${MAIN_COLORS.CONTROLS_CRITICAL}`,
        },
      },
    },
  },
  MuiNativeSelect: {
    select: {
      fontWeight: 800,
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE,
      '&:focus': {
        backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
        color: MAIN_COLORS.UI_LIGHT,
      },
    },
  },
  MuiInputLabel: {
    formControl: {
      zIndex: 2,
      color: MAIN_COLORS.UI_MUTED_TEXT,
      left: 16,
      transform: `translate(0, 20px) scale(1)`,
      '&.Mui-focused': {
        color: MAIN_COLORS.CONTROLS_ACTIVE,
      },
      '&.Mui-error': {
        color: MAIN_COLORS.CONTROLS_CRITICAL,
      },
    },
    shrink: {
      transform: `translate(0, 10px) scale(0.8)`,
    },
  },
};

export const { MuiInput, MuiInputBase, MuiInputLabel, MuiNativeSelect } =
  overrides;
