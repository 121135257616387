import { makeStyles } from '@material-ui/styles';
import { MAIN_COLORS } from 'utils/colors';
import { BORDER_RADIUS } from 'utils/constants';

export const useStyles = makeStyles({
  primary: {
    color: MAIN_COLORS.UI_LIGHT,
    backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE,
    borderColor: MAIN_COLORS.CONTROLS_ACTIVE,
    '&:hover': {
      backgroundColor: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    '&:disabled': {
      borderColor: MAIN_COLORS.CONTROLS_SHAPE_DISABLED,
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE_DISABLED,
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    color: MAIN_COLORS.CONTROLS_ACTIVE,
    '&:hover': {
      color: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    '&:disabled': {
      // backgroundColor: MAIN_COLORS.UI_LIGHT,
    },
  },
  secondaryCritical: {
    backgroundColor: MAIN_COLORS.UI_LIGHT,
    color: MAIN_COLORS.CONTROLS_CRITICAL,
    '&:hover': {
      color: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
      },
    },
    '&:disabled': {
      backgroundColor: MAIN_COLORS.UI_LIGHT,
    },
  },
  primaryInverse: {
    backgroundColor: MAIN_COLORS.BACKGROUND_LIGHT,
    color: MAIN_COLORS.CONTROLS_ACTIVE,
    '&:hover': {
      color: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    '&:disabled': {
      // backgroundColor: MAIN_COLORS.UI_LIGHT,
    },
  },
  secondaryInverse: {
    backgroundColor: 'transparent',
    color: MAIN_COLORS.UI_LIGHT,
    '&:disabled': {
      color: MAIN_COLORS.CONTROLS_DISABLED,
    },
  },
  primaryCritical: {
    backgroundColor: MAIN_COLORS.CONTROLS_CRITICAL,
    color: MAIN_COLORS.UI_LIGHT,
    '&:hover': {
      backgroundColor: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
      // '& path': {
      //   // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      //   stroke: MAIN_COLORS.CONTROLS_CRITICAL_HOVER,
      // },
    },
    // '&:disabled': {
    //   backgroundColor: MAIN_COLORS.UI_LIGHT,
    // },
  },
  default: {
    backgroundColor: MAIN_COLORS.CONTROLS_SHAPE,
    color: MAIN_COLORS.CONTROLS_ACTIVE,
    '&:hover': {
      color: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
    '&:disabled': {
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE_DISABLED,
    },
  },
  muted: {
    backgroundColor: MAIN_COLORS.TRANSPARENT,
    color: MAIN_COLORS.CONTROLS_MUTED,
    '&:hover': {
      color: MAIN_COLORS.CONTROLS_MUTED_HOVER,
      '& path': {
        // fill: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
        stroke: MAIN_COLORS.CONTROLS_ACTIVE_HOVER,
      },
    },
  },
  root: {
    borderRadius: BORDER_RADIUS,
    padding: '0 16px',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all linear 0.25s',
    '-webkit-tap-highlight-color': MAIN_COLORS.TRANSPARENT,
    '&:disabled': {
      cursor: 'not-allowed',
      color: MAIN_COLORS.CONTROLS_DISABLED,
    },
  },
  button: {
    fontWeight: 800,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    '&.slim': {
      height: 48,
    },
    '&.extraslim': {
      height: 40,
    },
  },
});
