import { makeStyles } from '@material-ui/styles';
import { Theme, theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';
import { BORDER_RADIUS } from 'utils/constants';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    padding: '12px 16px',
    boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.07)`,
  },
  dialogContent: {
    padding: spacing(2),
  },
  root: {
    '& > .MuiDialog-container': {
      alignItems: 'flex-end',
      [breakpoints.up('sm')]: {
        alignItems: 'center',
      },
    },
  },
  paper: {
    borderRadius: `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0`,
    margin: 0,
    padding: 0,
    minWidth: '100vw',
    // minHeight: '80vh',
    [breakpoints.up('sm')]: {
      minHeight: 'unset',
      margin: spacing(4),
      borderRadius: BORDER_RADIUS,
      minWidth: 'auto',
      width: 414,
    },
    maxWidth: 900,
    '&.isDesktop': {
      minHeight: 400,
    },
  },
  formControl: {
    margin: '8px 0',
    width: '100%',
  },
  withLabel: {
    '& > .MuiInputBase-root': {
      alignItems: 'flex-end',
      padding: '8px 16px',
      '& > .MuiInputBase-input': {
        padding: 0,
      },
    },
  },
  snack: {
    backgroundColor: MAIN_COLORS.UI_DARK,
    borderRadius: 12,
    fontSize: 17,
    lineHeight: '20px',
    fontWeight: 'normal',
    letterSpacing: '0.3px',
    justifyContent: 'center',
  },
}));
