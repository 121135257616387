import { makeStyles } from '@material-ui/styles';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
  },
  list: {
    paddingTop: 16,
  },
  logout: {
    paddingTop: 16,
  },
}));
