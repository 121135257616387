import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { BORDER } from 'utils/constants';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    '&.onClick': {
      cursor: 'pointer',
    },
  },
  container: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: BORDER,
    '&.last': {
      borderBottom: 0,
    },
    '&.area': {
      height: 'auto',
      alignItems: 'flex-start',
      paddingTop: 16,
      paddingBottom: 16,
    },
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
  title: {
    '&.area': {
      paddingRight: 16,
    },
  },
  value: {
    '&.area': {
      flex: 1,
      textAlign: 'right',
    },
  },
}));
