import { Overrides } from '@material-ui/core/styles/overrides';

const overrides: Overrides = {
  MuiListItemAvatar: {
    // root: {
    //   minWidth: 40,
    // },
  },
};

export const { MuiListItemAvatar } = overrides;
