import React, { FC } from 'react';
import { useRouter } from 'next/router';
import useSWR, { useSWRConfig } from 'swr';

import { Button, ListItemBasic } from 'components/ui';

import { getTestDate } from 'utils';
import { editPatient, getPlans, getTests } from 'utils/api';

import { useStyles } from './styles';

interface Props {
  patient: Patient;
  onArchive: () => void;
  user: User;
}

export const PatientProfile: FC<Props> = ({ patient, onArchive, user }) => {
  const css = useStyles();
  const router = useRouter();
  const { mutate } = useSWRConfig();

  const { data: tests = [] } = useSWR(
    ['/api/tests/', patient.id],
    (url, patientId) => getTests(patientId),
    {
      revalidateOnMount: false,
    }
  );

  const { data: plans = [] } = useSWR('/api/plans/', getPlans);

  const currentPlan = plans.find((p) => p.id === user?.profile.current_plan);

  const lastActive = tests.length
    ? getTestDate(tests.sort((a, b) => b.id - a.id)[0].created_at)
    : 'N/A';

  const disabledArchivePatient =
    !currentPlan.profile_archivation ||
    (patient.is_archived &&
      user?.profile?.active_patients >= currentPlan?.max_seats);

  const arhiveUnarchivePacient = async () => {
    await editPatient({
      ...patient,
      is_archived: !patient.is_archived,
    });
    await mutate('/api/patients/');
    await mutate('/api/auth/user/');
    onArchive();
  };

  return patient ? (
    <div>
      <div className={css.list}>
        <ListItemBasic title="Name" value={patient.name} />
        <ListItemBasic title="Gender" value={patient.gender} />
        <ListItemBasic title="Age" value={String(patient.age || 'N/A')} />
        <ListItemBasic title="Notes" value={patient.notes} area />
        <ListItemBasic title="Last Active" value={lastActive} last />
      </div>
      <Button
        variant="default"
        className={css.button}
        onClick={() => {
          router.push('?action=profile-edit', `${patient.hash_url}`, {
            shallow: true,
          });
        }}
        disabled={patient.is_archived}
      >
        Edit…
      </Button>
      <Button
        variant="default"
        className={css.button}
        onClick={arhiveUnarchivePacient}
        disabled={disabledArchivePatient}
        data-test={`${patient.is_archived ? 'restore' : 'archive'}-patient${
          disabledArchivePatient ? '-disabled' : ''
        }`}
      >
        {patient.is_archived ? 'Restore' : 'Archive'}
      </Button>
      <Button
        variant="secondaryCritical"
        className={css.button}
        onClick={() => {
          router.push('?action=profile-delete', `${patient.hash_url}`, {
            shallow: true,
          });
        }}
        data-test="delete-patient"
      >
        Delete…
      </Button>
    </div>
  ) : null;
};
