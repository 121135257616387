import React, { FC } from 'react';
import { useMediaQuery } from '@material-ui/core';
import cx from 'clsx';

import { theme } from 'theme';
import { Label } from 'components/ui';

import { TAB_PATH } from 'utils/constants';

import { PatientsTab, SettingsTab, HelpTab } from './tabs';
import { useStyles } from './styles';

interface Props {
  path: TAB_PATH;
  selected: boolean;
}

type AppTabComponentType = {
  [TKey in TAB_PATH]: JSX.Element;
};

const appTabComponent = (active: boolean): AppTabComponentType => ({
  [TAB_PATH.OBSERVABLES]: <PatientsTab active={active} />,
  [TAB_PATH.SETTINGS]: <SettingsTab active={active} />,
  [TAB_PATH.HELP]: <HelpTab active={active} />,
});

type AppTabTitleType = {
  [TKey in TAB_PATH]: string;
};

const appTabTitle: AppTabTitleType = {
  [TAB_PATH.OBSERVABLES]: 'Observables',
  [TAB_PATH.SETTINGS]: 'Settings',
  [TAB_PATH.HELP]: 'Help',
};

export const AppTab: FC<Props> = ({ path, selected }) => {
  const css = useStyles();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={cx(css.root, { selected })}>
      {appTabComponent(selected)[path]}
      <Label variant="nano" bold={lg} className={cx(css.label, { selected })}>
        {appTabTitle[path]}
      </Label>
    </div>
  );
};
