// import pkg from '../../../package.json';
import { MAIN_COLORS } from 'utils/colors';

// export const PACKAGE_VERSION = pkg.version;

// UI
export const BODY_FONT_SIZE = 17;
export const BODY_LINE_HEIGHT = '24px';
export const CAPTION_FONT_SIZE = 13.2;
export const CONTENT_WIDTH = 440;
export const CONTENT_XL_WIDTH = 600;
export const COL2_MENU_WIDTH = 320;
export const BORDER_RADIUS = 8;

export const MENU_HEIGHT_MOBILE = 48;
export const MENU_WIDTH_SMALL = 72;
export const MENU_WIDTH = 128;
export const MENU_WIDTH_LARGE = 200;
export const TAB_BAR_HEIGHT_MOBILE = 60;
export const TOP_BAR_HEIGHT = 64;
export const LEFT_COLUMN_MENU_WIDTH = 320;
export const TRANSITION_DURATION = 0.25;
export const TRANSITION = `all linear ${TRANSITION_DURATION}s`;

export const FONT_FAMILY = [
  'Helvetica Neue',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

export const FONT_WEIGHT_NORMAL = 400;
export const FONT_WEIGHT_LABEL = 500;
export const FONT_WEIGHT_BOLD = 800;

export const BORDER_WIDTH = '1px';
export const BORDER = `${BORDER_WIDTH} solid ${MAIN_COLORS.UI_BORDER}`;
export const BORDER_BOX_SHADOW = '0px 2px 3px rgba(0, 0, 0, 0.07)';

export const PUBLIC_URL = new URL(process.env.PUBLIC_URL).origin;

export enum TAB_PATH {
  OBSERVABLES = '/observables',
  SETTINGS = '/settings',
  HELP = '/help',
}

export const prefixes = ['Prefix', 'Dr.'] as const;
export type Prefix = typeof prefixes[number];
