import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { useRouter } from 'next/router';
import cx from 'clsx';

import { Button, ButtonRound } from 'components/ui';
import { IconBack } from 'components/ui/icons/IconBack';
import { IconMoreActions } from 'components/ui/icons/IconMoreActions';

import { useStyles } from './styles';

interface Props {
  title: string;
  rootStack: boolean;
  onClickActionIcon?: () => void;
  isActionIconActive?: boolean;
  disableBack?: boolean;
}

export const NavBar: FC<Props> = ({
  title,
  rootStack,
  onClickActionIcon,
  isActionIconActive,
  disableBack,
}) => {
  const css = useStyles();
  const router = useRouter();

  return (
    <div className={css.root}>
      <div>
        {!rootStack && !disableBack && (
          <Button
            name="Back"
            aria-label="Back"
            variant="secondary"
            onClick={router.back}
          >
            <IconBack />
            Back
          </Button>
        )}
      </div>

      <div className={cx(css.title, { rootStack })}>
        <Typography variant={rootStack ? 'h2' : 'h3'}>{title}</Typography>
      </div>

      {onClickActionIcon && (
        <div className={cx(css.actionIcon, { isActionIconActive })}>
          <ButtonRound
            onClick={onClickActionIcon}
            data-test={`navbar-action-${title}`}
          >
            <IconMoreActions />
          </ButtonRound>
        </div>
      )}
    </div>
  );
};
