import React, { FC, ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import cx from 'clsx';

import { MAIN_COLORS } from 'utils/colors';

import { useStyles } from './styles';

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  propName: string;
  label?: string;
  options: readonly string[];
}

export const Select: FC<Props> = ({
  value,
  onChange,
  propName,
  label,
  options,
}) => {
  const css = useStyles();

  const [focused, setFocused] = useState(false);

  return (
    <div className={css.formControl}>
      <FormControl
        fullWidth
        className={cx(css.withLabel, { label: Boolean(label) })}
      >
        <InputLabel shrink>{label}</InputLabel>
        <NativeSelect
          value={value}
          onChange={onChange}
          inputProps={{
            name: propName,
            id: propName,
          }}
          className={css.select}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {options.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </NativeSelect>
        <div className={css.icon}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1315 6H5.86852C5.06982 6 4.59343 6.89015 5.03647 7.5547L9.16795 13.7519C9.56377 14.3457 10.4362 14.3457 10.8321 13.7519L14.9635 7.5547C15.4066 6.89015 14.9302 6 14.1315 6Z"
              fill={
                focused ? MAIN_COLORS.UI_LIGHT : MAIN_COLORS.CONTROLS_ACTIVE
              }
            />
          </svg>
        </div>
      </FormControl>
    </div>
  );
};
