import { getTestDateTime, testsRevSortFn } from 'utils';
import { MAIN_COLORS } from 'utils/colors';

export interface Data {
  dates: number[];
  params: Param[];
}

export interface Param {
  key: string;
  name: string;
  color: string;
  path: string;
  values?: number[];
  readable?: string[];
}

export enum PARAMS {
  TIME = 'time',
  DISTANCE_RATIO = 'distance_ratio',
  DEVIATIONS = 'deviations',
  DEVIATIONS_SUM = 'deviations_sum',
  MEAN_DEVIATION = 'mean_deviation',
  VELOCITY_PARAM_1 = 'velocity_param_1',
  VELOCITY_PARAM_2 = 'velocity_param_2',
}

export const getData = (): Data => {
  return {
    dates: [],
    params: [],
  };
};

const normalize = (arr: number[]): number[] => {
  const min = Math.min(...arr);
  const max = Math.max(...arr) - min;
  return arr.map((v) => (v - min) / max);
};

export const getSorted = (tests: Test[]): Test[] => {
  return tests.sort(testsRevSortFn);
};

export const getDates = (timeSortedTests: Test[]): number[] => {
  const dates: number[] = timeSortedTests.map((t) =>
    getTestDateTime(t.created_at)
  );
  const normalDates = normalize(dates);
  return normalDates;
};

const getNormalValues = (tests: Test[], key: string): number[] => {
  const values = tests.map((t) => t[key]);
  switch (key) {
    case PARAMS.DISTANCE_RATIO:
      values.push(1, 3);
      break;
    case PARAMS.DEVIATIONS:
      values.push(0, 160);
      break;
    case PARAMS.DEVIATIONS_SUM:
      values.push(0, 3000);
      break;
    case PARAMS.MEAN_DEVIATION:
      values.push(0, 30);
      break;
    case PARAMS.TIME:
      values.push(5000, 30000);
      break;
    default:
      values.push(0, 0);
  }
  const normalized = normalize(values);
  normalized.splice(normalized.length - 2);
  return normalized;
};

const getPath = (values: number[], dates: number[]): string => {
  let s = '';
  let started = false;
  values.forEach((v, i) => {
    if (!started) {
      if (!started && v > 0) {
        started = true;
        s += 'M ';
        s += dates[i] * 100 + ' ' + (1 - v) * 100;
      }
    } else {
      s += ' L ';
      s += dates[i] * 100 + ' ' + (1 - v) * 100;
    }
  });
  return s;
};

const getReadable = (tests: Test[], key: string): string[] => {
  switch (key) {
    case PARAMS.DISTANCE_RATIO:
      return tests.map((t) => (t[key] > 0 ? t[key].toFixed(2) : '–'));
    case PARAMS.DEVIATIONS:
      return tests.map((t) => (t[key] > 0 ? String(t[key]) : '–'));
    case PARAMS.TIME:
      return tests.map((t) =>
        t[key] > 0 ? (t[key] / 1000).toFixed(1) + 's' : '–'
      );
    case PARAMS.DEVIATIONS_SUM:
      return tests.map((t) => (t[key] > 0 ? t[key].toFixed(1) : '–'));
    case PARAMS.MEAN_DEVIATION:
      return tests.map((t) => (t[key] > 0 ? t[key].toFixed(1) : '–'));
    default:
      return tests.map((t) => (t[key] > 0 ? String(t[key]) : '–'));
  }
};

export const isPathMuted = (paramId: number, currentParamId: number): boolean =>
  currentParamId != paramId && currentParamId != -1;

export const isDotVisible = (
  testId: number,
  paramId: number,
  currentParamId: number
): boolean =>
  testId != -1 && (currentParamId == paramId || currentParamId == -1);

export const isLabelVisible = (
  paramId: number,
  currentParamId: number,
  isLegendVisible: boolean
): boolean =>
  isLegendVisible && (currentParamId == paramId || currentParamId == -1);

export const getTestCSSLeft = (data: Data, testId: number): string =>
  data.dates[testId] * 100 + '%';

export const getLabelCSSLeft = (data: Data, testId: number): string =>
  data.dates[testId] < 0.5 ? getTestCSSLeft(data, testId) : 'unset';

export const getLabelCSSRight = (data: Data, testId: number): string =>
  data.dates[testId] > 0.5 ? (1 - data.dates[testId]) * 100 + '%' : 'unset';

let cachedTops: number[] = undefined;
let catchedKeys: number[] = undefined;
export const getLabelCSSTop = (
  params: Param[],
  paramId: number,
  testId: number,
  containerHeight: number,
  lableHeight: number,
  currentParamId: number
): string => {
  if (currentParamId === paramId) {
    return (1 - params[paramId].values[testId]) * 100 + '%';
  }
  if (cachedTops === undefined || paramId === 0) {
    cachedTops = params.map(
      (param) => containerHeight * (1 - param.values[testId])
    );
    const unsorted = [...cachedTops];
    cachedTops.sort((a, b) => a - b);
    const sorted = [...cachedTops];
    catchedKeys = [];
    unsorted.forEach((v, i) => {
      catchedKeys.push(sorted.indexOf(v));
      sorted[catchedKeys[i]] = -Infinity;
    });
    let k = containerHeight + lableHeight;
    for (let i = cachedTops.length - 1; i >= 0; i--) {
      k = Math.min(cachedTops[i], k - lableHeight);
      cachedTops[i] = k;
    }
    k = -lableHeight;
    for (let i = 0; i < cachedTops.length; i++) {
      k = Math.max(cachedTops[i], k + lableHeight);
      cachedTops[i] = k;
    }
  }
  return cachedTops[catchedKeys[paramId]] + 'px';
};

export const getTestMarkPath = (v: number): string => 'M' + v * 100 + ' 0V100';

const getParam = (key: string, name: string, color: string): Param => ({
  key,
  name,
  color,
  values: null,
  readable: null,
  path: '',
});

export const getParams = (tests: Test[], dates: number[]): Param[] => {
  const d: Param[] = [
    getParam(PARAMS.DISTANCE_RATIO, 'Distance Ratio', MAIN_COLORS.GRAPH_PARAM1),
    getParam(PARAMS.DEVIATIONS_SUM, 'Deviation Sum', MAIN_COLORS.GRAPH_PARAM2),
    getParam(PARAMS.DEVIATIONS, 'Deviations', MAIN_COLORS.GRAPH_PARAM3),
    getParam(PARAMS.MEAN_DEVIATION, 'Mean Deviation', MAIN_COLORS.GRAPH_PARAM4),
    getParam(PARAMS.TIME, 'Movement Time', MAIN_COLORS.GRAPH_PARAM5),
  ];
  return d.map((t) => {
    const valuesNormalized: number[] = getNormalValues(tests, t.key);
    return {
      ...t,
      readable: getReadable(tests, t.key),
      values: valuesNormalized,
      path: getPath(valuesNormalized, dates),
    };
  });
};

export const getCurrentTestId = (
  event: MouseEvent,
  div: HTMLDivElement,
  testsNormalized: Data
): number => {
  const rect = div.getBoundingClientRect();
  const n = Math.min(1, Math.max(0, (event.clientX - rect.left) / rect.width));
  let d = Infinity;
  let id = -1;
  testsNormalized.dates.forEach((v: number, i: number) => {
    const td = Math.abs(v - n);
    if (td < d) {
      id = i;
      d = td;
    } else {
      return id;
    }
  });
  return id;
};
