import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
  },
  button: {
    width: '100%',
  },
}));
