import { makeStyles } from '@material-ui/styles';

import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 16,
  },
  formControl: {
    margin: '8px 0',
    '& > .Mui-disabled': {
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE_DISABLED,
    },
  },
  button: {
    width: '100%',
  },
  withLabel: {
    '& > .MuiInputBase-root': {
      alignItems: 'flex-end',
      padding: '8px 16px',
      '& > .MuiInputBase-input': {
        padding: 0,
      },
    },
  },
  dialogBlock: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}));
