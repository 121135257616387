import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Color } from '../constants';

export const palette: PaletteOptions = {
  primary: {
    light: Color.primaryLight,
    main: Color.primaryMain,
    dark: Color.primaryDark,
    contrastText: Color.primaryContrastText,
  },
  secondary: {
    light: Color.secondaryLight,
    main: Color.secondaryMain,
    dark: Color.secondaryDark,
    contrastText: Color.secondaryContrastText,
  },
  text: {
    secondary: Color.textSecondary,
  },
  error: {
    light: Color.errorLight,
    main: Color.errorMain,
    dark: Color.errorDark,
    contrastText: Color.errorContrastText,
  },
  background: {
    default: Color.backgroundDefault,
  },
  divider: Color.divider,
};
