import React, { FC } from 'react';

import { MAIN_COLORS } from 'utils/colors';

interface Props {
  color?: MAIN_COLORS;
}

export const IconCopy: FC<Props> = ({
  color = MAIN_COLORS.CONTROLS_ACTIVE,
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7"
        y="2"
        width="11"
        height="11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7H2V18H13V13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
