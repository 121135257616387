import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@material-ui/core';

import { Button, Link, ListItemThick } from 'components/ui';

import { PATHS } from 'utils/paths';

import { useStyles } from './styles';

export const HelpList: FC = () => {
  const css = useStyles();
  const router = useRouter();

  return (
    <div className={css.root}>
      <Link href={PATHS.HELP_HOW_TO_START}>
        <ListItemThick
          title="How to start"
          disclosure
          selected={router.pathname === PATHS.HELP_HOW_TO_START}
        />
      </Link>
      <Link href={PATHS.HELP_TEST_ANALYTICS}>
        <ListItemThick
          title="Test Analytics"
          disclosure
          selected={router.pathname === PATHS.HELP_TEST_ANALYTICS}
          last
        />
      </Link>

      <div style={{ padding: '32px 16px 16px' }}>
        <Typography>
          Have any questions or suggestions? Do not hesitate to contact us.
        </Typography>
      </div>

      <div style={{ padding: '0 16px' }}>
        <Link href="mailto:inbox@motoom.app">
          <Button variant="default" className={css.button}>
            Contact Us
          </Button>
        </Link>
      </div>
    </div>
  );
};
