import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

import { palette } from './palette';
import { overrides } from './overrides';
import { typography } from './typography';
import { props } from './props';
import { breakpoints } from './breakpoints';

export const theme = createTheme({
  palette,
  overrides,
  typography,
  props,
  breakpoints,
});

export type Theme = typeof theme;
