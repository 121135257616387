import React from 'react';
import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
  withStyles,
} from '@material-ui/core';
import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 32,
      padding: 0,
    },
    switchBase: {
      padding: 4,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: MAIN_COLORS.UI_CALM,
          opacity: 1,
          border: 'none',
        },
      },
      '&:hover': {
        backgroundColor: MAIN_COLORS.CONTROLS_SHAPE,
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 32 / 2,
      backgroundColor: MAIN_COLORS.CONTROLS_SHAPE,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
