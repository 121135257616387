import React, { FC } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

import { MAIN_COLORS } from 'utils/colors';

interface Props {
  variant?: 'dark' | 'light' | 'green';
  animated?: boolean;
}

export const MotoomIcon: FC<Props> = ({ variant = 'dark', animated }) => {
  let fill = MAIN_COLORS.UI_DARK; // dark
  let fillNext = MAIN_COLORS.UI_LIGHT; // dark

  switch (variant) {
    case 'light':
      fill = MAIN_COLORS.UI_LIGHT;
      fillNext = MAIN_COLORS.UI_DARK;
      break;
    case 'green':
      fill = MAIN_COLORS.CONTROLS_ACTIVE;
      fillNext = MAIN_COLORS.UI_DARK;
      break;
    default:
      fill = MAIN_COLORS.UI_DARK;
      fillNext = MAIN_COLORS.UI_LIGHT;
      break;
  }

  const { scrollY } = useViewportScroll();
  const fillAnimated = animated
    ? useTransform(scrollY, [0, 64], [fill, fillNext])
    : fill;

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM28.4979 6.25L33.8738 29.5075H30.2856L26.7102 14.0193L21.7941 35.2589H18.2059L13.3025 14.0193L9.71436 29.5075H6.1262L11.5021 6.25H15.0902L20.0064 27.4895L24.9098 6.25H28.4979Z"
        fill={fillAnimated}
      />
    </svg>
  );
};
