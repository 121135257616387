import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Typography,
  AppBar,
  Toolbar,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
  SnackbarOrigin,
} from '@material-ui/core';
import useSWR from 'swr';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

import {
  AppTab,
  Link,
  PatientsList,
  Button,
  LoginDialog,
  PatientProfile,
  ButtonRound,
  Label,
  PatientProfileForm,
  ChangePatientLink,
  ChangePatientLinkConfirm,
  SettingsList,
  HelpList,
} from 'components/ui';
import { MotoomIcon } from 'components/ui/icons/MotoomIcon';
import { IconClose } from 'components/ui/icons/IconClose';

import { getInitialUser, logout, editPatient, getPatients } from 'utils/api';
import { TAB_PATH } from 'utils/constants';
import { MAIN_COLORS } from 'utils/colors';
import { LAYOUTS, PATHS, PATHS_ACTIONS } from 'utils/paths';

import { useStyles } from './styles';
import { theme } from 'theme';

interface Props {
  noTopBar?: boolean;
  title?: string;
  layout?: string;
  doctorName?: string;
  user?: User;
}

const getStaticTitle = (pathname: string) => {
  if (pathname.includes(TAB_PATH.OBSERVABLES)) {
    return 'Observables';
  }
  if (pathname.includes(TAB_PATH.SETTINGS)) {
    return 'Settings';
  }
  if (pathname.includes(TAB_PATH.HELP)) {
    return 'Help';
  }
  return null;
};

const anchorOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const autoHideDuration = 6000;

const Col2: FC<{ pathname: string; patients: Patient[]; user: User }> = ({
  pathname,
  patients,
  user,
}) => {
  if (pathname.includes(TAB_PATH.OBSERVABLES)) {
    return <PatientsList patients={patients} user={user} />;
  }
  if (pathname.includes(TAB_PATH.SETTINGS)) {
    return <SettingsList user={user} />;
  }
  if (pathname.includes(TAB_PATH.HELP)) {
    return <HelpList />;
  }
  return null;
};

export const Layout: FC<Props> = ({
  children,
  layout,
  doctorName,
  user: initialUser,
}) => {
  const css = useStyles();
  const router = useRouter();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const { data: user } = useSWR('/api/auth/user/', getInitialUser, {
    fallbackData: initialUser,
    revalidateOnFocus: false,
  });

  const { data: patients = [], mutate } = useSWR<Patient[]>(
    user ? '/api/patients/' : null,
    getPatients,
    { revalidateOnFocus: false }
  );

  const initialPatient = patients.find(
    (p) => p.hash_url === router.query.observable
  );

  const [patient, setPatient] = useState(initialPatient);
  const [showChangedPatientSnack, setShowChangedPatientSnack] = useState(false);
  const [showChangedLinkSnack, setShowChangedLinkSnack] = useState(false);
  const [login, setLogin] = useState(false);

  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 64], [-64, 0]);
  const opacity = useTransform(scrollY, [64, 250], [0, 1]);

  const savePatient = async (p: EditPatientPayload, cb: () => void) => {
    const newPatient = await editPatient(p);
    if (newPatient) {
      const x = await mutate(
        patients.map((pat) => (pat.id === newPatient.id ? newPatient : pat))
      );
      if (x.find((pat) => pat.hash_url === p.hash_url)) {
        router.replace('', `${p.hash_url}`);
      }
      cb();
    }
  };

  const onPatientProfileEditSave = async (p: EditPatientPayload) => {
    savePatient(p, () => setShowChangedPatientSnack(true));
  };

  const onPatientProfileLinkChange = async (p: EditPatientPayload) => {
    savePatient(p, () => setShowChangedLinkSnack(true));
  };

  const close4thColumn = (patientHashUrl: string) => {
    router.push('', `${patientHashUrl}`, {
      shallow: true,
    });
  };

  const onArchive = (patientHashUrl: string) => {
    router.replace('?action=profile', `${patientHashUrl}`, {
      shallow: true,
    });
  };

  const onChangePatientLink = (patientHashUrl: string) => {
    router.push('?action=patient-link-edit-confirm', `${patientHashUrl}`, {
      shallow: true,
    });
  };

  useEffect(() => {
    const p = patients.find((p) => p.hash_url === router.query.observable);
    if (p) {
      setPatient(p);
    }
  }, [patients, router.query.observable]);

  const Col4Header: FC<{ title: string; patient?: Patient }> = ({
    title,
    patient,
  }) => {
    return (
      <div className={css.col4Header}>
        {patient && (
          <>
            <Typography variant="h3">{title}</Typography>
            <ButtonRound onClick={() => close4thColumn(patient.hash_url)}>
              <IconClose lg />
            </ButtonRound>
          </>
        )}
      </div>
    );
  };

  const Col4: FC<{ currentPatient: Patient }> = ({ currentPatient }) => {
    if (
      router.pathname === PATHS.OBSERVABLE &&
      (router.query.action === PATHS_ACTIONS.PROFILE ||
        router.query.action === PATHS_ACTIONS.PROFILE_DELETE)
    ) {
      return (
        <div>
          <Col4Header title="Profile" patient={currentPatient} />
          <div style={{ padding: 16 }}>
            <PatientProfile
              patient={currentPatient}
              onArchive={() => onArchive(currentPatient.hash_url)}
              user={user}
            />
          </div>
        </div>
      );
    }

    if (
      router.pathname === PATHS.OBSERVABLE &&
      router.query.action === PATHS_ACTIONS.PROFILE_EDIT
    ) {
      return (
        <div>
          <Col4Header title="Edit" patient={currentPatient} />
          <div style={{ padding: 16 }}>
            <PatientProfileForm
              submitText="Save"
              onFormSubmit={(p) =>
                onPatientProfileEditSave({ ...p, id: currentPatient.id })
              }
              initialPatient={currentPatient}
            />
          </div>
        </div>
      );
    }

    if (
      (router.pathname === PATHS.OBSERVABLE &&
        router.query.action === PATHS_ACTIONS.PROFILE_LINK_EDIT) ||
      router.query.action === PATHS_ACTIONS.PROFILE_LINK_EDIT_CONFIRM
    ) {
      console.log(router.query.action);
      return (
        <div>
          <Col4Header title="Profile's Link" patient={currentPatient} />
          <div style={{ padding: 16 }}>
            <ChangePatientLink
              patient={currentPatient}
              onChange={() => onChangePatientLink(currentPatient.hash_url)}
            />
            <ChangePatientLinkConfirm
              patient={currentPatient}
              show={
                router.query.action === PATHS_ACTIONS.PROFILE_LINK_EDIT_CONFIRM
              }
              onChange={(newPatient) => {
                onPatientProfileLinkChange(newPatient);
              }}
            />
          </div>
        </div>
      );
    }

    return <Col4Header title="" />;
  };

  if (layout === LAYOUTS.NOT_FOUND) {
    return (
      <div className={css.notFound}>
        <Link href="/" className={css.appIcon2}>
          <div className={css.logo}>
            <MotoomIcon variant="dark" animated />
            <div>
              <Typography
                variant="h2"
                component="div"
                style={{ paddingLeft: 12, color: MAIN_COLORS.UI_DARK }}
              >
                Motoom
              </Typography>
            </div>
          </div>
        </Link>
        {children}
      </div>
    );
  }

  if (layout === LAYOUTS.PATIENT_TEST) {
    return (
      <div className={css.patientTestRoot}>
        <div className={css.patientTestLogoContainer}>
          <Label style={{ color: MAIN_COLORS.UI_MUTED_TEXT }}>motoom</Label>
        </div>

        <Label>
          This test provided to you by {doctorName || 'your doctor'}
        </Label>
        {children}
      </div>
    );
  }

  if (layout === LAYOUTS.HOME) {
    const mainLogo = xs ? '' : 'Motoom';

    return (
      <div style={{ position: 'relative' }}>
        <LoginDialog open={login} setOpen={setLogin} />
        <motion.div
          style={{
            position: 'fixed',
            zIndex: 100,
            width: '100%',
            y: router.pathname === PATHS.SIGNUP ? 0 : y,
            opacity: router.pathname === PATHS.SIGNUP ? 1 : opacity,
          }}
        >
          <AppBar className={css.appBar}>
            <Toolbar className={css.toolBar}>
              <Link href="/" className={css.appIcon2}>
                <MotoomIcon />
                <Typography
                  variant="h2"
                  component="div"
                  style={{ paddingLeft: 12 }}
                >
                  {mainLogo}
                </Typography>
              </Link>
              <div className={css.appBarButtons}>
                <Button
                  variant="secondary"
                  onClick={user ? logout : () => setLogin(true)}
                  style={{ marginRight: 6 }}
                  extraslim
                  data-test={`app-bar-${user ? 'logout' : 'login'}`}
                >
                  {user ? 'Logout' : 'Log In'}
                </Button>
                {user ? (
                  <Link href={PATHS.OBSERVABLES}>
                    <Button extraslim data-test="app-bar-cabinet">
                      Your Office
                    </Button>
                  </Link>
                ) : (
                  <Link href={PATHS.SIGNUP}>
                    <Button extraslim data-test="app-bar-signup">
                      Create account
                    </Button>
                  </Link>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </motion.div>

        {children}
      </div>
    );
  }

  return (
    <>
      <div className={css.wrapper}>
        <div className={css.layout}>
          <div className={css.menu}>
            <div className={css.appIcon}>
              <Link href={PATHS.HOME} className={css.appLogo}>
                <MotoomIcon variant="light" />
              </Link>
            </div>

            <Link href={PATHS.OBSERVABLES}>
              <AppTab
                path={TAB_PATH.OBSERVABLES}
                selected={router.pathname.includes(TAB_PATH.OBSERVABLES)}
              />
            </Link>
            <Link href={PATHS.SETTINGS}>
              <AppTab
                path={TAB_PATH.SETTINGS}
                selected={router.pathname.includes(TAB_PATH.SETTINGS)}
              />
            </Link>
            <Link href={PATHS.HELP}>
              <AppTab
                path={TAB_PATH.HELP}
                selected={router.pathname.includes(TAB_PATH.HELP)}
              />
            </Link>
          </div>

          {md && (
            <div className={css.col2}>
              <div className={css.staticNavbar}>
                <Typography variant="h2">
                  {getStaticTitle(router.pathname)}
                </Typography>
              </div>
              <Col2
                pathname={router.pathname}
                patients={patients}
                user={user}
              />
            </div>
          )}

          <div className={css.content}>{children}</div>

          <div className={css.col4}>
            <Col4 currentPatient={patient} />
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={anchorOrigin}
        open={showChangedPatientSnack}
        autoHideDuration={autoHideDuration}
        onClose={() => setShowChangedPatientSnack(false)}
      >
        <SnackbarContent className={css.snack} message="Profile changed" />
      </Snackbar>

      <Snackbar
        anchorOrigin={anchorOrigin}
        open={showChangedLinkSnack}
        autoHideDuration={autoHideDuration}
        onClose={() => setShowChangedLinkSnack(false)}
      >
        <SnackbarContent className={css.snack} message="Link changed" />
      </Snackbar>
    </>
  );
};
