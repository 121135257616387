import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiAppBar } from './MuiAppBar';
import { MuiSnackbarContent } from './MuiSnackbar';
import { MuiButton, MuiFab } from './MuiButton';
import {
  MuiInput,
  MuiInputBase,
  MuiInputLabel,
  MuiNativeSelect,
} from './MuiInput';
import { MuiSvgIcon } from './MuiSvgIcon';
import {
  MuiDialogTitle,
  MuiDialogContentText,
  MuiDialogActions,
  MuiBackdrop,
  MuiDialogContent,
} from './MuiDialog';
import { MuiAvatar } from './MuiAvatar';
import { MuiListItemAvatar } from './MuiListItemAvatar';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiTab } from './MuiTab';
import { MuiTooltip } from './MuiTooltip';
import { MuiList, MuiListItem } from './MuiList';
import { MuiContainer } from './MuiContainer';

export const overrides: Overrides = {
  MuiAppBar,
  MuiCssBaseline,
  MuiSnackbarContent,
  MuiButton,
  MuiInput,
  MuiInputBase,
  MuiInputLabel,
  MuiNativeSelect,
  MuiFab,
  MuiSvgIcon,
  MuiDialogTitle,
  MuiDialogContentText,
  MuiDialogActions,
  MuiAvatar,
  MuiMenuItem,
  // MuiModal,
  MuiBackdrop,
  MuiTab,
  MuiTooltip,
  MuiDialogContent,
  MuiList,
  MuiListItem,
  MuiListItemAvatar,
  MuiContainer,
};
