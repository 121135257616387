import React, { FC } from 'react';
import { MAIN_COLORS } from 'utils/colors';

export const IconBack: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.99997L2 9.99999L10 18"
        stroke={MAIN_COLORS.CONTROLS_ACTIVE}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
