import { MAIN_COLORS } from 'utils/colors';

export const MuiCssBaseline = {
  '@global': {
    body: {
      color: MAIN_COLORS.UI_DARK,
      backgroundColor: MAIN_COLORS.BACKGROUND_LIGHT,
    },
    button: {
      color: 'inherit',
      border: 'none',
    },
  },
} as any;
