import React, { FC } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import cx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { ButtonRound, PatientProfileForm } from 'components/ui';
import { IconClose } from 'components/ui/icons/IconClose';

import { TransitionUp } from 'utils/transitions';
import { addPatient, getPatients } from 'utils/api';

import { useStyles } from './styles';

interface Props {
  open?: boolean;
  setOpen: (show: boolean) => void;
}

export const AddPatientDialog: FC<Props> = ({ open = false, setOpen }) => {
  const css = useStyles();
  const isDesktop = true;
  const { mutate } = useSWRConfig();

  const { data: patients, mutate: patientsMutate } = useSWR<Patient[]>(
    '/api/patients/',
    getPatients
  );

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  const onSubmit = async (p: AddPatientPayload) => {
    const newPatient = await addPatient(p);

    if (newPatient) {
      await patientsMutate([...patients, newPatient]);
      await mutate('/api/auth/user/');
    }
    setOpen(false);
  };

  return (
    <Dialog
      TransitionComponent={TransitionUp}
      open={open}
      onClose={onClose}
      classes={{ root: css.root, paper: cx(css.paper, { isDesktop }) }}
      scroll="paper"
    >
      <DialogTitle className={css.dialogTitle}>
        <div className={css.info}>
          <div>
            <Typography variant="h3">New Profile</Typography>
          </div>
          <ButtonRound onClick={() => setOpen(false)}>
            <IconClose lg />
          </ButtonRound>
        </div>
      </DialogTitle>

      <DialogContent className={css.dialogContent}>
        <PatientProfileForm submitText="Create" onFormSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};
