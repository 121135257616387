import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {},
  listRoot: {
    cursor: 'pointer',
    '&.new': {
      backgroundColor: '#CCF0EA',
    },
  },
  listHeader: {
    display: 'grid',
    gridTemplateColumns: '64px auto 72px 72px',
    gridTemplateRows: `auto`,
    gridTemplateAreas: `'col1 col2 col3 col4'`,
    height: 36,
    paddingRight: 16,
  },
  listHeaderCol1: {
    display: 'flex',
    gridArea: 'col1',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listHeaderCol2: {
    display: 'flex',
    gridArea: 'col2',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 24,
  },
  listHeaderCol3: {
    display: 'flex',
    gridArea: 'col3',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listHeaderCol4: {
    display: 'flex',
    gridArea: 'col4',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '64px auto 72px 72px',
    gridTemplateRows: `auto`,
    gridTemplateAreas: `'col1 col2 col3 col4'`,
    height: 60,
    paddingRight: 16,
  },
  listCol1: {
    gridArea: 'col1',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listCol2: {
    gridArea: 'col2',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 24,
  },
  listCol3: {
    gridArea: 'col3',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  listCol4: {
    gridArea: 'col4',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  testImageContainer: {
    paddingTop: spacing(0),
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: MAIN_COLORS.BACKGROUND_LIGHTER_GREY,
  },
  testImage: {
    display: 'block',
    mixBlendMode: 'darken',
  },
  testParamsList: {
    paddingBottom: spacing(4),
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
  },
  dataParamsList: {
    paddingBottom: spacing(1),
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
  },
  dataExplanation: {
    paddingBottom: spacing(4),
    color: MAIN_COLORS.UI_READABLE_TEXT,
  },
  noTests: {
    padding: spacing(4),
  },
}));
