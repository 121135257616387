import { Overrides } from '@material-ui/core/styles/overrides';

import { BORDER_RADIUS } from 'utils/constants';
import { Color } from '../constants';

const overrides: Overrides = {
  MuiButton: {
    // root: {
    //   borderRadius: BORDER_RADIUS,
    //   textTransform: 'none',
    //   boxShadow: 'none !important',
    // },
  },
  MuiFab: {
    // root: {
    //   backgroundColor: 'white',
    //   '&$focusVisible': {
    //     color: 'white',
    //     backgroundColor: Color.primaryMain,
    //   },
    // },
  },
};

export const { MuiButton, MuiFab } = overrides;
