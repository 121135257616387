import React, { FC } from 'react';

import { MAIN_COLORS } from 'utils/colors';

interface Props {
  lg?: boolean;
  color?: MAIN_COLORS;
}

export const IconClose: FC<Props> = ({
  lg,
  color = MAIN_COLORS.CONTROLS_ACTIVE,
}) => {
  const size = lg ? 24 : 20;
  const path = lg
    ? 'M3.6001 3.6001L12.0001 12.0001M20.4001 20.4001L12.0001 12.0001M12.0001 12.0001L20.4001 3.6001M12.0001 12.0001L3.6001 20.4001'
    : 'M3 3L10 10M17 17L10 10M10 10L17 3M10 10L3 17';
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path} stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
