import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {},
  link: {
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
  },
  text: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  button: { width: '100%', marginTop: spacing(2) },
}));
