import { makeStyles } from '@material-ui/styles';

import { MAIN_COLORS } from 'utils/colors';

export const useStyles = makeStyles(() => ({
  formControl: {
    padding: '8px 0',
  },
  button: {
    width: '100%',
  },
  select: {
    backgroundColor: `${MAIN_COLORS.CONTROLS_SHAPE} !important`,
    boxShadow: 'none',
    '&.Mui-focused': {
      backgroundColor: `${MAIN_COLORS.CONTROLS_ACTIVE} !important`,
      color: MAIN_COLORS.UI_LIGHT,
    },
  },
  withLabel: {
    '& > .MuiInputBase-root': {
      alignItems: 'flex-end',
      padding: '8px 16px',
      '& > .MuiInputBase-input': {
        padding: 0,
      },
    },
  },
  withLabelTextInput: {
    '& > .MuiInputBase-root': {
      padding: '28px 16px 8px',
    },
  },
}));
